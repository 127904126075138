import React, { useEffect, useRef, useState } from 'react'
import "../../../src/CreaterDashboard/Series/allSeries.css"
import { add_cast, pink_add, pink_video, pinkgallery, plusgray, themecross, videoPlayButton } from '../../Constants/images'
import TagModal from './addTag'
import EpisodeModal from './addEpisodeModal'
import CastModal from './addCastModal'
import ERR_MESSAGE from '../../Helper/error-helper'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { NotificationManager } from 'react-notifications';
import ROUTES from '../../Helper/route-helper'
import { useNavigate, useLocation } from 'react-router'
import ProgressBarComponent from '../../ControlComponents/progressBar'
import uploadToS3 from '../component/fileUpload'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
function CreateSeries() {
  const location = useLocation()
  const Id = location.state.id
  const [refresh, setRefresh] = useState(false)
  const [progress, setProgress] = useState();
  const [progressLoading, setProgressLoading] = useState(false)
  const [episodes, setEpisodes] = useState([]);
  const [episodes1, setEpisodes1] = useState([]);
  const [cast, setCast] = useState([]);
  const [cast1, setCast1] = useState([]);
  const [formattedDuration, setFormattedDuration] = useState("")
  const [thumbnail, setThumbnail] = useState("")
  const [thumbnail1, setThumbnail1] = useState("")
  const [totalDuration, setTotalDuration] = useState("")
  const [tags, setTags] = useState([]);
  const [tagModal, setTagModal] = useState(false)
  const [episodeModal, setEpisodeModal] = useState(false)
  const [castModal, setCastModal] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [thumbnailFileUrl, setThumbnailFileUrl] = useState("");
  const [episodeUrl, setEpisodeUrl] = useState("");
  const [episodeFileUrl, setEpisodeFileUrl] = useState("");
  const [castUrl, setCastUrl] = useState("");
  const [castFileUrl, setCastFileUrl] = useState("");
  const [combinedData, setCombinedData] = useState([]);
  const [clipData, setClipData] = useState([]);
  const [trailerData, setTrailerData] = useState([])
  const [clipData1, setClipData1] = useState([]);
  const [trailerData1, setTrailerData1] = useState([])
  const [errors, setErrors] = useState("")
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [category, setCategory] = useState([])
  const nextSerialNo = useRef(1);
  const [info, setInfo] = useState({
    title: "",
    description: "",
    episodeTitle: "",
    episodeSummary: "",
    castName: "",
    castSeriesName: "",
    category: ""
  })

  async function getDetailsAPI() {
    if (Id != null) {
      setLoading(true)
      let result = await apiHelper.getRequest("creator/my-series-details?id=" + Id)
      if (result.code == DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false)
        setInfo(result?.data)
        setTags(result?.data?.tags?.split(","))
        setThumbnailFileUrl(result?.data?.thumbnail)
        const trailer = result?.data?.series_clips?.filter(clip => clip?.type === 1);
        const clip = result?.data?.series_clips?.filter(clip => clip?.type === 2);
        setClipData1(clip)
        setTrailerData1(trailer)
        setEpisodes1(result?.data?.episodes)
        setCast1(result?.data?.series_casts)
        setInfo({
          ...info,
          category: result?.data?.category?.id,
          title: result.data.title,
          description: result.data.description
        })
      }
      else if (result.code == 401) {
        let refreshToken = localStorage.getItem("refresh-token")
        let data = {
          "refresh_token": refreshToken,
          "scope": "refresh_token"
        }
        let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
        if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
          localStorage.setItem("token", refreshTokenResult.data.token)
          localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
          setRefresh(true)
        }
      }
    }

  }

  useEffect(() => {
    getDetailsAPI()
    getCatAPI()
  }, [refresh, Id])

  useEffect(() => {
    const totalDurationInSeconds = episodes1.reduce((sum, episode) => {
      if (episode.duration) {
        const [hours, minutes, seconds] = episode.duration.split(':').map(Number);
        return sum + (hours * 3600) + (minutes * 60) + seconds;
      }
      return sum;
    }, 0);

    const hours = Math.floor(totalDurationInSeconds / 3600);
    const minutes = Math.floor((totalDurationInSeconds % 3600) / 60);
    const seconds = totalDurationInSeconds % 60;

    let totalDurationString = '';

    if (hours > 0) {
      totalDurationString += `${hours}h ${minutes < 10 ? '0' : ''}${minutes}min ${seconds < 10 ? '0' : ''}${seconds}s`;
    } else if (minutes > 0) {
      totalDurationString += `${minutes}min ${seconds < 10 ? '0' : ''}${seconds}s`;
    } else {
      totalDurationString += `${seconds}s`;
    }
    setTotalDuration(totalDurationString);
  }, [episodes1]);


  const handleSaveCast = (cast, cast1) => {
    setCast((prevEpisodes) => [...prevEpisodes, cast]);
    setCast1((prevEpisodes) => [...prevEpisodes, cast1]);
  };

  useEffect(() => {
    const combinedArray = [...clipData1, ...trailerData1];
    setCombinedData(combinedArray);
  }, [clipData1, trailerData1]);

  const handleChange = (e) => {
    setErrors("")
    const name = e.target.name
    const value = e.target.value
    setInfo({
      ...info,
      [name]: value
    })
  }

  const fileChangeEpisode = async (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        const handleMetadataLoaded = () => {
          return new Promise(async (resolve) => {
            const durationInSeconds = videoElement.duration;
            const formattedDuration = formatDuration(durationInSeconds);
            setFormattedDuration(formattedDuration)
            const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
            setThumbnail(thumbnailURL)
            fileUploadEpisode(fileThumbnail, file)
            const formData = new FormData();
            formData.append('mediaFile', file);
            const videoURL = URL.createObjectURL(file);
            setEpisodeUrl(videoURL)
            resolve();
          });
        };

        videoElement.addEventListener('loadedmetadata', async () => {
          await handleMetadataLoaded();
        });

        videoElement.src = URL.createObjectURL(file);
      }
    }
  };


  const fileChange = (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type == 'image/svg+xml' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        const imageURL = URL.createObjectURL(file);
        setThumbnailUrl(imageURL);
        fileUpload(file)
      }
    }
  }

  const fileChangeCast = (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        const imageURL = URL.createObjectURL(file);
        setCastUrl(imageURL);
        fileUploadCast(file)
      }
    }
  };

  async function getCatAPI() {
    setLoading(true)
    let result = await apiHelper.getRequest("creator/challenge-category")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCategory(result?.data)
      setLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  function modifyDuration(duration) {
    const durationParts = duration?.split(':');
    if (durationParts && durationParts[0] === '00') {
      return durationParts.slice(1)?.join(':');
    }
    return duration;
  }

  const generateThumbnail = async (videoElement, files) => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoElement.videoWidth;
    const videoHeight = videoElement.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
    const thumbnailURL = canvas.toDataURL('image/jpeg');
    const fileThumbnail = dataURLtoFile(thumbnailURL, files[0].name.split(".")[0]);
    return { thumbnailURL, fileThumbnail };
  };

  const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
    return new Promise((resolve) => {
      const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
      videoElement.currentTime = seekTime;
      videoElement.addEventListener('seeked', async () => {
        const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
        resolve({ thumbnailURL, fileThumbnail });
      });
    });
  };

  const fileChangeClip = async (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files;
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.addEventListener('loadedmetadata', async () => {
          const durationInSeconds = videoElement.duration;
          const formattedDuration = formatDuration(durationInSeconds);
          const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
          const formData = new FormData();
          formData.append('mediaFile', file);
          const videoURL = URL.createObjectURL(file);
          fileUpload2(fileThumbnail, file, formattedDuration)
          setClipData((prevData) => [
            ...prevData,
            {
              video: videoURL,
              thumbnail: thumbnailURL,
              duration: formattedDuration,
              type: 2,
              is_active: 1,
            },
          ]);
        });
        videoElement.src = URL.createObjectURL(file);
      }
    }

  };


  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const fileChangeTrailer = async (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files;
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.addEventListener('loadedmetadata', async () => {
          const durationInSeconds = videoElement.duration;
          const formattedDuration = formatDuration(durationInSeconds);
          const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
          const formData = new FormData();
          formData.append('mediaFile', file);
          const videoURL = URL.createObjectURL(file);
          fileUpload3(fileThumbnail, file, formattedDuration)
          setTrailerData((prevData) => [
            ...prevData,
            {
              video: videoURL,
              thumbnail: thumbnailURL,
              duration: formattedDuration,
              type: 1,
              is_active: 1,
            },
          ]);
        });

        videoElement.src = URL.createObjectURL(file);
      }
    }
  };

  const fileUpload = async (file, thumb) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation) {
          setProgressLoading(false)
          setThumbnailFileUrl(state.videoLocation)
        }
      }

    } catch (error) {
    }
  };

  const fileUploadCast = async (file, thumb) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation) {
          setProgressLoading(false)
          setCastFileUrl(state.videoLocation)
        }
      }

    }
    catch (error) {
    }
  };

  const fileUploadEpisode = async (thumb, file) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setEpisodeFileUrl(state.videoLocation)
          setThumbnail1(state.thumbnailLocation)
        }
      }

    }
    catch (error) {
    }
  };

  const fileUpload2 = async (thumb, file, formattedDuration) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setClipData1(prevData => [
            ...prevData,
            {
              video: state.videoLocation,
              thumbnail: state.thumbnailLocation,
              duration: formattedDuration,
              type: 2,
              is_active: 1,
            }
          ]
          )
        }
        ;
      }
    }
    catch (error) {
    }
  };
  const fileUpload3 = async (thumb, file, formattedDuration) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setTrailerData1(prevData => [
            ...prevData,
            {
              video: state.videoLocation,
              thumbnail: state.thumbnailLocation,
              duration: formattedDuration,
              type: 1,
              is_active: 1,
            }
          ]
          );
        }
      }
    }
    catch (error) {
    }
  };

  const handleRemoveClip = (index) => {
    setClipData((prevData) => {
      const updatedClipData = [...prevData];
      updatedClipData.splice(index, 1);
      return updatedClipData;
    });

    setClipData1((prevData1) => {
      const updatedClipData1 = [...prevData1];
      updatedClipData1.splice(index, 1);
      return updatedClipData1;
    });
  };

  const handleRemoveTrailer = (index) => {
    setTrailerData((prevData) => {
      const updatedClipData = [...prevData];
      updatedClipData.splice(index, 1);
      return updatedClipData;
    });
    setTrailerData1((prevData) => {
      const updatedClipData = [...prevData];
      updatedClipData.splice(index, 1);
      return updatedClipData;
    });
  };

  const handleRemoveEpisode = (index) => {
    setEpisodes((prevData) => {
      const updatedClipData = [...prevData];
      updatedClipData.splice(index, 1);

      const updatedItems = updatedClipData.map((item, idx) => ({
        ...item,
        sequence_no: idx + 1
      }));

      return updatedItems;
    });

    setEpisodes1((prevData) => {
      const updatedClipData = [...prevData];
      updatedClipData.splice(index, 1);

      const updatedItems = updatedClipData.map((item, idx) => ({
        ...item,
        sequence_no: idx + 1
      }));

      return updatedItems;
    });
  };


  const handleSaveEpisode = (episode, episode1) => {
    setEpisodes((prevEpisodes) => [...prevEpisodes, episode]);
    setEpisodes1((prevEpisodes) => [...prevEpisodes, episode1])
  };

  const handleValidation1 = () => {
    let errors = {};
    let formIsValid = true;
    const { episodeTitle, episodeSummary } = info;
    if (!thumbnail1 || thumbnail1.trim().length === 0) {
      formIsValid = false;
      errors["thumbnail1"] = ERR_MESSAGE.EPISODE_EMPTY
    }
    if (!episodeTitle || episodeTitle?.trim().length === 0) {
      formIsValid = false;
      errors["episodeTitle"] = ERR_MESSAGE.TITLE_EMPTY
    }
    if (!episodeSummary || episodeSummary.trim().length === 0) {
      formIsValid = false;
      errors["episodeSummary"] = ERR_MESSAGE.SUMMARY_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmitEpisode = (e) => {
    e.preventDefault();
    const sequenceNo = nextSerialNo.current;
    if (handleValidation1()) {
      const episodeObject = {
        video: episodeUrl,
        thumbnail: thumbnail,
        title: info.episodeTitle,
        description: info.episodeSummary,
        duration: formattedDuration,
        is_active: 1,
        sequence_no: sequenceNo
      };
      const episodeObject1 = {
        video: episodeFileUrl,
        thumbnail: thumbnail1,
        title: info.episodeTitle,
        description: info.episodeSummary,
        duration: formattedDuration,
        is_active: 1,
        sequence_no: sequenceNo
      };
      handleSaveEpisode(episodeObject, episodeObject1);
      nextSerialNo.current++;

      setInfo({
        ...info,
        episodeTitle: "",
        episodeSummary: ""
      })
      setThumbnail1("")
      setEpisodeModal(false);
    }
  };

  const seriesNameRef = useRef(null);
  const tagsRef = useRef(null);
  const summaryRef = useRef(null);
  const thumbnailRef = useRef(null);
  const clipRef = useRef(null);
  const trailerRef = useRef(null);
  const episodeRef = useRef(null);
  const categoryRef = useRef(null);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const { title, description, category } = info;
    if (!title || title.trim().length === 0) {
      formIsValid = false;
      errors["title"] = ERR_MESSAGE.SERIES_NAME_EMPTY
    }
    if (!tags || tags?.length === 0) {
      formIsValid = false;
      errors["tags"] = ERR_MESSAGE.TAGS_EMPTY
    }
    if (!category) {
      formIsValid = false;
      errors["category"] = ERR_MESSAGE.EMPTY_CATEORY
    }
    if (!description || description.trim().length === 0) {
      formIsValid = false;
      errors["description"] = ERR_MESSAGE.SUMMARY_EMPTY
    }
    if (!thumbnailFileUrl) {
      formIsValid = false;
      errors["thumbnailFileUrl"] = ERR_MESSAGE.THUMBNAIL_EMPTY
    }
    if (!clipData1 || clipData1?.length === 0) {
      formIsValid = false;
      errors["clipData1"] = ERR_MESSAGE.CLIP_EMPTY
    }
    if (!trailerData1 || trailerData1?.length === 0) {
      formIsValid = false;
      errors["trailerData1"] = ERR_MESSAGE.TRAILOR_EMPTY
    }
    if (!episodes1 || episodes1?.length === 0) {
      formIsValid = false;
      errors["episodes1"] = ERR_MESSAGE.EPISODES_EMPTY
    }
    if (!cast1 || cast1?.length === 0) {
      formIsValid = false;
      errors["cast1"] = ERR_MESSAGE.CAST_EMPTY
    }
    const firstErrorField = Object.keys(errors)[0];
    const firstErrorRef =
      firstErrorField === 'title' ? seriesNameRef :
        firstErrorField === 'description' ? summaryRef :
          firstErrorField === 'tags' ? tagsRef :
            firstErrorField === 'category' ? categoryRef :
              firstErrorField === 'thumbnailFileUrl' ? thumbnailRef :
                firstErrorField === 'clipData1' ? clipRef :
                  firstErrorField === 'trailerData1' ? trailerRef :
                    firstErrorField === 'episodes1' ? episodeRef :
                      null;

    if (firstErrorRef && firstErrorRef.current) {
      firstErrorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setErrors(errors);
    return formIsValid;
  };

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  async function handleSubmit(e) {
    e.preventDefault()
    if (handleValidation()) {
      let data = {
        "title": info.title,
        "year": currentYear,
        "tags": tags,
        "is_featured": 1,
        "is_talented": 1,
        "category_id": parseInt(info.category),
        "duration": totalDuration,
        "thumbnail": thumbnailFileUrl,
        "description": info.description,
        "is_active": 1,
        "episodesData": episodes1,
        "seriesCastData": cast1,
        "trailerAndClipData": combinedData
      }
      setLoading(true)
      let result = await apiHelper.postService("creator/add-series", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false)
        setInfo({
          ...info,
          title: "",
          description: ""
        })
        setTags([])
        setThumbnailFileUrl("")
        setClipData1([])
        setTrailerData1([])
        setEpisodes1([])
        setCast1([])
        localStorage.setItem("message", result.message)
        navigate(ROUTES.CREATER_SERIES);
      }
      else {
        setLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  async function handleEditAPI(e) {
    e.preventDefault()
    if (handleValidation()) {
      let data = {
        "seriesId": Id,
        "channel_id": 2,
        "title": info.title,
        "year": currentYear,
        "tags": tags,
        "is_featured": 1,
        "is_talented": 1,
        "category_id": parseInt(info.category),
        "duration": totalDuration,
        "thumbnail": thumbnailFileUrl,
        "description": info.description,
        "is_active": 1,
        "episodesData": episodes1,
        "seriesCastData": cast1,
        "trailerAndClipData": combinedData
      }
      setLoading(true)
      let result = await apiHelper.postService("creator/edit-series", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false)
        setInfo({
          ...info,
          title: "",
          description: ""
        })
        setTags([])
        setThumbnailFileUrl("")
        setClipData1([])
        setTrailerData1([])
        setEpisodes1([])
        setCast1([])
        localStorage.setItem("message", result.message)
        navigate(-1);
      }
      else {
        setLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      episodes1,
      result.source.index,
      result.destination.index
    );

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      sequence_no: index + 1
    }));

    setEpisodes1(updatedItems);
  };


  return (
    <div>
      {isLoading && <ReactLoader />}
      {progressLoading && <>
        <ProgressBarComponent progress={progress} />
      </>
      }
      <div className="main-inner-content">
        <div className="row">
          <div className="col-12 py-3">
            <h3 className="fw-600 text-black" ref={!info?.title ? seriesNameRef : summaryRef}> {Id != null ? "Edit Series" : "Create Series"}</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12" >
            <div className="create-series-wrapper newcreate-series-wrapper p-3 p-sm-5">
              <form>
                <div className="row">
                  <div className='col-12 col-lg-6'>
                    <div className='row'>
                      <div className="col-12" >
                        <div className="form-group mb-3">
                          <label className="fw-500 mb-2 forgot-label" ref={tagsRef} >Series Name</label>
                          <input type="text" className="form-control  fw-500 forgot-input place-grey" placeholder="Enter Here" name='title' value={info.title} onChange={handleChange}
                          />
                          {errors.title && <span className="err_msg">{errors.title}</span>}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between" ref={categoryRef}>
                            <label className="fw-500 mb-2 forgot-label">Tags</label>
                            <a style={{ cursor: "pointer" }} className="f18-size fw-500" onClick={() => setTagModal(!tagModal)}><img src={pink_add} alt="add-icon" className="mb-1" /> <span className="pink-add" >Add</span></a>
                          </div>
                          <input type="text" style={{ pointerEvents: "none" }}
                            className="form-control  fw-500 forgot-input place-grey" placeholder="Please select the tags by clicking on add " value={tags.join(", ")} />
                          {errors.tags && <span className="err_msg">{errors.tags}</span>}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-0">
                          <label className="fw-500 mb-2 forgot-label">Category</label>
                          <select className='form-select  fw-500 border-edit w-100' name="category" value={info.category} onChange={handleChange}>
                            <option value="">Select Here</option>
                            {
                              category?.map((item) => (
                                <>
                                  <option value={item.id}>{item.name}</option>
                                </>
                              ))
                            }
                          </select>
                        </div>
                        {errors.category && <span className="err_msg">{errors.category}</span>}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className="form-group mb-3">
                      <label className="fw-500 mb-2 forgot-label">Summary</label>
                      <textarea ref={thumbnailRef} className="fw-500 forgot-input place-grey d-block w-100" placeholder="Enter Here" rows="10" name='description' value={info.description} onChange={handleChange} ></textarea>
                      {errors.description && <span className="err_msg">{errors.description}</span>}
                    </div>
                  </div>
                </div>
                <label className="fw-500 mb-0 forgot-label mt-3">Add Thumbnail</label>
                <div className="row">
                  <div className="col-12 col-md-4 position-relative pt-3">
                    <div className=" video-clip-create position-relative">
                      <div className='d-flex m-auto justify-content-center align-items-center h-100'>
                        <div className=''>
                          <div className='text-center'>
                            <img src={pinkgallery}></img>
                          </div>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100 pt-2">
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="fw-500 create-text border-bottom ">Add Thumbnail</span>
                          </div>
                        </div>
                      </div>
                      <input type="file" className="video-file-schedule"
                        accept=".jpg, .jpeg, .png, .svg, " ref={clipRef}
                        onChange={fileChange} style={{ cursor: "pointer" }} />
                      {errors.thumbnailFileUrl && <span className="err_msg">{errors.thumbnailFileUrl}</span>}
                    </div>
                  </div>
                  {
                    thumbnailFileUrl && <div className="col-12  col-md-8   pt-4 pt-md-0">
                      <div className="add-cast-head pt-3 ms-0">
                        <div className="position-relative">
                          <img src={thumbnailFileUrl} alt="" className="clipimg-create" />
                          <div className='cro-epi' onClick={() => {
                            setThumbnailFileUrl("")
                          }} style={{ cursor: "pointer" }}>
                            <img src={themecross} className='img-fluid'></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <label className="fw-500 mb-0 forgot-label mt-3">Add Clip</label>
                <div className="row">
                  <div className="col-12 col-md-4 position-relative pt-3">
                    <div className=" video-clip-create position-relative">
                      <div className='d-flex m-auto justify-content-center align-items-center h-100'>
                        <div className=''>
                          <div className='text-center'>
                            <img src={pink_video}></img>
                          </div>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100 pt-2">
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="fw-500 create-text border-bottom ">Add Clip</span>
                          </div>
                        </div>
                      </div>
                      <input type="file" className="video-file-schedule" accept=".mp4, .mov, .avi,.mkv"
                        onChange={fileChangeClip} style={{ cursor: "pointer" }} ref={trailerRef} />
                      {errors.clipData1 && <span className="err_msg">{errors.clipData1}</span>}
                    </div>
                  </div>
                  <div className="col-12  col-md-8   pt-4 pt-md-0">
                    <div className="add-cast-head pt-3 ms-0">
                      {
                        clipData1.map((clip, index) => (
                          <>
                            <div className="position-relative" key={clip.id}>
                              <img src={clip.thumbnail} alt="" className="clipimg-create" />
                              <div className='cro-epi' style={{ cursor: "pointer" }} onClick={() => handleRemoveClip(index)} >
                                <img src={themecross} className='img-fluid'></img>
                              </div>
                              <div className='play-epi'>
                                <img src={videoPlayButton} className='img-fluid'></img>
                              </div>
                            </div></>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <label className="fw-500 mb-0 forgot-label mt-3">Add Trailer</label>
                <div className="row" >
                  <div className="col-12 col-md-4 position-relative pt-3">
                    <div className=" video-clip-create position-relative">
                      <div style={{ cursor: "pointer" }} className='d-flex m-auto justify-content-center align-items-center h-100'>
                        <div className=''>
                          <div className='text-center'>
                            <img src={pink_video}></img>
                          </div>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100 pt-2">
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="fw-500 create-text border-bottom ">Add Trailer</span>
                          </div>
                        </div>
                      </div>
                      <input type="file" className="video-file-schedule" accept=".mp4, .mov, .avi, .mkv"
                        onChange={fileChangeTrailer} style={{ cursor: "pointer" }}
                        ref={episodeRef} />
                      {errors.trailerData1 && <span className="err_msg">{errors.trailerData1}</span>}
                    </div>
                  </div>
                  <div className="col-12  col-md-8   pt-4 pt-md-0">
                    <div className="add-cast-head pt-3 ms-0">
                      {
                        trailerData1.map((clip, index) => (
                          <>
                            <div className="position-relative" >
                              <img src={clip.thumbnail} alt="" className="clipimg-create" />
                              <div className='cro-epi' style={{ cursor: "pointer" }} onClick={() => handleRemoveTrailer(index)} >
                                <img src={themecross} className='img-fluid' ></img>
                              </div>
                              <div className='play-epi'>
                                <img src={videoPlayButton} className='img-fluid'></img>
                              </div>
                            </div></>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <label className="fw-500 forgot-label mt-3 mb-0">Add Episode</label>
                <div className="row">
                  <div className="episode-anchor position-relative pe-0 pt-3" style={{ cursor: "pointer" }} onClick={() => setEpisodeModal(!episodeModal)}>
                    <div className=" video-episode-create position-relative">
                      <div className='d-flex m-auto justify-content-center align-items-center h-100'>
                        <div className=''>
                          <div className='text-center'>
                            <img src={pink_video}></img>
                          </div>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100 pt-2" >
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="fw-500 create-text border-bottom ">Add Episode</span>
                          </div>
                        </div>
                      </div>
                      {errors.episodes1 && <span className="err_msg">{errors.episodes1}</span>}
                    </div>
                  </div>
                  <div className="episode-add-detail position-relative pt-4 pt-md-0 ms-0 ps-4">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="episodeList" direction="horizontal">
                        {(provided) => (
                          <div className="add-cast-head pt-3" {...provided.droppableProps} ref={provided.innerRef}>
                            {episodes1?.map((item, index) => (
                              <Draggable key={item?.id || index} draggableId={(item?.id || index).toString()} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="item"
                                  >
                                    <div className="add-sub-itemepi">
                                      <div className="position-relative">
                                        <img src={item.thumbnail} alt="" className="episodeimg-create text-center mx-auto" />
                                        <span className='episodes-label' style={{ bottom: "0px" }}>Episode {item.sequence_no ? item.sequence_no?.toString().padStart(2, '0') : (index + 1)?.toString().padStart(2, '0')}  </span>
                                        <div className="cro-episode" style={{ cursor: "pointer" }} onClick={() => handleRemoveEpisode(index)}>
                                          <img src={themecross} className="img-fluid" alt="remove episode" />
                                        </div>
                                        <div className="play-epi">
                                          <img src={videoPlayButton} className="img-fluid" alt="play episode" />
                                        </div>
                                      </div>
                                      <div className="p-2">
                                        <h6 className="fw-600 blue-text f16-size mb-0">
                                          {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                                        </h6>
                                        <p className="fw-500 video-text mb-0">{item.duration}</p>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>

                </div>
                <label className="fw-500 mb-2 forgot-label mt-3">Add Cast & Crew</label>
                <div className="row">
                  <div className="cast-anchor position-relative" style={{ cursor: "pointer" }} onClick={() => setCastModal(!castModal)}>
                    <div className=" video-cast-create position-relative">
                      <div className='d-flex m-auto justify-content-center align-items-center h-100'>
                        <div className=''>
                          <div className='text-center'>
                            <img src={add_cast}></img>
                          </div>
                          <div className="d-flex m-auto justify-content-center align-items-center h-100 pt-2" >
                            <img src={plusgray} alt="" className="img-fluid me-1" />
                            <span className="fw-500 create-text border-bottom ">Add Cast</span>
                          </div>
                        </div>
                      </div>
                      {errors.cast1 && <span className="err_msg">{errors.cast1}</span>}
                    </div>
                  </div>
                  <div className="cast-anchor-detail position-relative pe-0 pt-4 pt-md-0 ps-4">
                    <div className="add-cast-head">
                      {
                        cast1.map((item) => (
                          <div className="add-sub-itemp-3 px-4">
                            <div className="text-center">
                              <img src={item.image} alt="" className="cast-item-img text-center mx-auto" />
                            </div>
                            <h6 className="f18-size fw-500 cast-item-head">{item.name.charAt(0).toUpperCase() + item.name.slice(1)} <br /></h6>
                            <p className="fw-400 cast-item-text mb-0">{item.name_in_series.charAt(0).toUpperCase() + item.name_in_series.slice(1)}</p>
                          </div>

                        ))
                      }


                    </div>

                  </div>
                </div>
                {
                  Id != null ? <div className="my-5 cast-btn text-center mx-auto" onClick={handleEditAPI}>
                    <a href="" className="common-btn w-100" >Submit</a>
                  </div> : <div className="my-5 cast-btn text-center mx-auto" onClick={handleSubmit}>
                    <a href="" className="common-btn w-100" >Submit</a>
                  </div>
                }

              </form>
            </div>
          </div>
        </div>
        {
          tagModal && <TagModal setTagModal={setTagModal} setTags={setTags} tags={tags} setErrors={setErrors} />
        }
        {
          episodeModal && <EpisodeModal setEpisodeModal={setEpisodeModal} handleChange={handleChange} info={info} fileChangeEpisode={fileChangeEpisode} episodeFileUrl={episodeFileUrl} thumbnail1={thumbnail1} errors={errors} handleSubmitEpisode={handleSubmitEpisode} setErrors={setErrors} setInfo={setInfo} setThumbnail1={setThumbnail1} />
        }

        {
          castModal && <CastModal setCastModal={setCastModal} setInfo={setInfo} handleChange={handleChange} info={info} fileChangeCast={fileChangeCast} handleSaveCast={handleSaveCast} castUrl={castUrl} castFileUrl={castFileUrl} setCastFileUrl={setCastFileUrl} setCastUrl={setCastUrl} setErrors={setErrors} errors={errors} />
        }

      </div >
    </div >
  )
}

export default CreateSeries