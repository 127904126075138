import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEMO_IMAGE_FIVE, DEMO_IMAGE_SIX, favGrey, favRed, noChannelFound, profile_image_placeholder, search } from '../../Constants/images';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ROUTES from '../../Helper/route-helper';

const UserListGift = () => {
    const [filter, setFilter] = useState(0)
    const [_, forceUpdate] = useState(0)
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [channelList, setChannelList] = useState([])

    async function getAPI() {
        setIsLoading(true)
        let url;
        if(localStorage.getItem("role") == 2){
             url = "creator/get-gift-sended-users"
        }
        else{
            url = "tipsAndGifts/get-gifted-channels"
        }

        let result = await apiHelper.getRequest(url)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
            setChannelList(result?.data)
            setIsLoading(false)
        }
        else if (result?.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

  
    useEffect(() => {
        getAPI()
    }, [refresh, filter]);

    const handleChange = async (e) => {
        let result = await apiHelper.getRequest(`tier/manage-tier?search=${e.target.value}`)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
            setChannelList(result?.data)
        }
    }

    return (
        <div className='main-inner-content'>
            {isLoading && <ReactLoader />}
            <section className="streaming py-6 response-padding">
                <div className="container">
                    <div className='row justify-content-end align-items-center mb-3 mb-md-0'>
                        <div className='col-12 col-lg-8 col-md-8 '>
                            <h3 className='mb-4 mb-md-0 fw-600'>{localStorage.getItem("role") == 1 ? "Creators":"Users"}</h3>
                        </div>
                        <div className='col-12 col-lg-4 col-md-4 px-0'>
                            {/* <div className="form-allchannel-search position-relative w-100">
                                <form><input
                                    onChange={handleChange}
                                    type="text" name="search" className="form-control" placeholder="Search" />
                                    <button type="button" className="icon-search"><img src={search} alt="" className="search-img" />
                                    </button>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {
                channelList?.length > 0 &&

                <section className="py-60">
                    <div className="container">
                        <div className="row">
                            {
                                channelList.map((channels) => {
                                    return (
                                        <div  key={channels.id} className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                                            <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    if (localStorage.getItem("role") == 1) {
                                                        navigate(ROUTES.GIFT_CONTENT_USER, {
                                                            state: { id: channels.creator_id }
                                                        });
                                                    } else {
                                                        navigate(ROUTES.GIFT_CONTENT, {
                                                            state: { id: channels.user_id }
                                                        });
                                                    }
                                                }}
                                            >
                                                <img src={channels?.profile_pic ? channels?.profile_pic : profile_image_placeholder} className="img-fluid channel-img" />

                                                <h4 className="channel-text fw-600 my-2">{channels.name}</h4>
                                            </div>
                                           
                                        </div>
                                     )
                                }) 

                         } 
                        </div>
                    </div>
                </section>
             } 

             {
                !isLoading &&
                <>
                    {
                        channelList.length <= 0 &&
                        <>    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                            <img src={DEMO_IMAGE_FIVE} alt="No Challenges" />

                        </div>
                        {
                            localStorage.getItem("role") == 1 ? 
                            <h6 className='text-center mx-5 my-2'>No creators yet</h6>
:                            <h6 className='text-center mx-5 my-2'>No users yet</h6>

                        }
                            </>
                    }
                </>
            } 
        </div>
    )
}

export default UserListGift

