import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';
import { mute_trailor, unmute_trailor } from '../../../Constants/images';
import { NextArrow, NextDisableArrow, PrevArrow, PrevDisbaleArrow } from '../../DashboardHome/Components/DashHomeSlider/buttons';
import PriceTag from '../../../CreaterDashboard/component/price-tag';
import BuyModal from '../../../Components/PaymentModal/BuyModal';

const FeatureSlider = ({ featuredList, check, getAPI,setIsLoading }) => {
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState({})
    const [activeSlide, setActiveSlide] = useState(0);
    const [isMuted, setIsMuted] = useState(() => {
        return localStorage.getItem('isMuted') === 'true';
    });
    const navigate = useNavigate();
    const videoRefs = useRef([]);
    const sliderRef = useRef(null);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [isFirstSlide, setIsFirstSlide] = useState(true);

    useEffect(() => {
        const currentVideo = videoRefs.current[activeSlide];
        if (currentVideo) {
            currentVideo.muted = isMuted;
            currentVideo.play().catch((error) => {
                console.log("Autoplay failed for the first video. Muting video and retrying play.", error);
                currentVideo.muted = true;
                currentVideo.play();
            });
        }
    }, [featuredList, isMuted, activeSlide]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: isFirstSlide  ? <PrevDisbaleArrow/>:<PrevArrow />,
        nextArrow: isLastSlide ? <NextDisableArrow/>: <NextArrow />,
        beforeChange: (current, next) =>{
            setIsFirstSlide(next === 0); 
            setIsLastSlide(next === featuredList?.length - 1); 
            setActiveSlide(next)
        },
        afterChange: (current) => handleVideoPlayback(current),
    };

    const handleVideoPlayback = (current) => {
        videoRefs.current.forEach((video, index) => {
            if (video) {
                if (index === current) {
                    video.muted = isMuted;
                    video.play().catch((error) => {
                        console.log("Autoplay failed. Muting video and retrying play.", error);
                        video.muted = true;
                        video.play();
                    });
                } else {
                    video.muted = true;
                    video.pause();
                }
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    const userMuted = localStorage.getItem('isMuted') === 'true';
                    setIsMuted(userMuted);
                    localStorage.setItem('focus', 1);
                } else {
                    localStorage.setItem("focus", 0)

                    setIsMuted(true);
                }
            },
            { threshold: 0.1 }
        );

        if (sliderRef.current) {
            observer.observe(sliderRef.current);
        }

        return () => {
            if (sliderRef.current) {
                observer.unobserve(sliderRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setIsMuted(true);
            } else {
                const userMuted = localStorage.getItem('isMuted') === 'true';
                setIsMuted(userMuted);

            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    const toggleMute = () => {
        setIsMuted(prevIsMuted => {
            const newMutedState = !prevIsMuted;
            localStorage.setItem('isMuted', newMutedState);
            return newMutedState;
        });
    };

    useEffect(() => {
        return () => {
            localStorage.setItem('isMuted', false);
            localStorage.removeItem('focus');
        };
    }, []);


    const handleSearch = (item) => {
        navigate(ROUTES.GLOBAL_SEARCH, { state: { item } })
    }

    return (
        <section ref={sliderRef}>
            <div className='container'>
                <div className="epsiode-slider mt-2 episode-carousel-main">
                    <Slider {...settings}>
                        {featuredList?.data?.slice(0, 10)?.map((item, index) => (
                            <div
                                key={item.id}
                                style={{ cursor: 'pointer' }}
                                className="epsoide-slider-wrapper position-relative"
                            >
                                <div className='d-flex gap-3 position-absolute align-items-center' style={{ top: 15, left: 45, cursor: 'pointer' }}
                                    onClick={() => {
                                        navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: item } });
                                    }}>
                                    <img
                                        src={item?.channelDetail?.profile_pic}
                                        style={{ height: "50px", width: "50px", borderRadius: "100%", objectFit: "cover" }}
                                        alt="channel"
                                    />
                                    <h5 className='text-white mb-0'>{item?.channelDetail?.name}</h5>
                                </div>
                                <div 
                                >
                                    <video
                                        className="img-fluid banner-video"
                                        src={item?.series_clips?.find(clip => clip.type === 1)?.video}
                                        autoPlay
                                        loop
                                        playsInline
                                        muted
                                        ref={(el) => (videoRefs.current[index] = el)}
                                        style={{ cursor: "pointer" }}
                                    />
                                    {
                                        (item.is_purchase == 0 && item.tier_details == null
                                        ) &&
                                        <PriceTag classes={"price_tag series-price-tag home-price-tag "} firstValue={`$${item.price}`} secondValue={"00"} />
                                    }

                                </div>
                                <div style={{ cursor: "pointer" }}>
                                    {
                                        item.tier_details == null ?
                                            <>{
                                                item.is_purchase == 0 ?
                                                    <h5 className="common-btn buy-feature" style={{ zIndex: 10 }}
                                                        onClick={() => {
                                                            setOpen(true)
                                                            setItem(item)
                                                        }
                                                        }>Buy Now</h5> :
                                                    <h5 className="common-btn buy-feature" style={{ zIndex: 10 }}
                                                        onClick={() => {
                                                            if (check == "discover") {
                                                                navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: item?.id } })
                                                            }
                                                            else {
                                                                navigate(`/series-list/series-detail/${item.id}`)
                                                            }
                                                        }
                                                    } >View</h5>
                                            }</>
                                            :

                                            <h5 className="common-btn buy-feature" style={{ zIndex: 10 }}
                                                onClick={() => {
                                                    if (item.tier_details?.is_purchased == 1) {
                                                        if (check == "discover") {
                                                            navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: item?.id } })
                                                        }
                                                        else {
                                                            navigate(`/series-list/series-detail/${item.id}`)
                                                        }
                                                    }
                                                    else {
                                                        localStorage.setItem('tierContentId', item.id + 'series')
                                                        navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
                                                    }
                                                }} >View</h5>
                                    }

                                    <div onClick={toggleMute} className="mute-toggle-btn">
                                        {isMuted ? <img src={mute_trailor} style={{ width: "30px" }} /> : <img src={unmute_trailor} style={{ width: "30px" }} />}
                                    </div>
                                </div>

                                <div className='detail-banner-slide'
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="home-bottom-text">
                                        <h5 className="trending-text f16-size">Featured</h5>
                                        <div className='d-flex align-items-center justify-content-start flex-wrap'
                                                                                 >
                                            <h2 className="white d-inline">{item?.title}</h2>
                                            <span className="f18-size white ms-4">{item?.episodes_count} {item?.episodes_count == 1 ? "Episode" : "Episodes"}</span>
                                        </div>
                                        <div className='gap-2 mt-0 button-home-banner'>
                                            <div className="align-items-center gap-2 mt-3 buttonflex">
                                                {item?.tags?.split(",").map((tag, idx) => (
                                                    <div key={idx} className="common-btn btn-white d-block" style={{ cursor: "pointer" }} onClick={() => handleSearch(tag)}>
                                                        <p className='m-0'>{tag}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                 
                       {open && <BuyModal item={item} setLoading={setIsLoading} setBuyModal={setOpen} itemType="1" getApi={getAPI} />}
                </div>
            </div>
        </section>
    );
};

export default FeatureSlider;
