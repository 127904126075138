import React, { useState, useEffect } from "react";
import {  noChallenges } from "../../Constants/images";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from "../../ControlComponents/react-loader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";
const ChooseChallenges = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [data, setData] = useState([])
  const tierItem = JSON.parse(localStorage.getItem('tierItem'))
  const [selectedList, setSelectedList] = useState([]);
  const navigate = useNavigate()
  const handleCheckboxChange = (id) => {
    setSelectedList((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((seriesId) => seriesId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/challenges-list")
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setIsLoading(false)
      setData(result?.data)
    }
    else if (result?.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getAPI()
  }, [refresh])

  async function handleSubmit() {
    if(selectedList?.length == 0) return
    setIsLoading(true);
    let data = {
      tier_id: tierItem.id,
      item_type: "4",
      item_id: selectedList,
    };
    let result = await apiHelper.postRequest("creator/add-tier-content", data);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false);
      navigate(ROUTES.CREATER_BUCKET_ACCESS)
      localStorage.setItem('message', result.message +'challenge')

    } else if (result.code === 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        refresh_token: refreshToken,
        scope: "refresh_token",
      };
      let refreshTokenResult = await apiHelper.postRequest(
        "auth/refresh-token",
        data
      );
      if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem(
          "refresh-token",
          refreshTokenResult.data.refresh_token
        );
        setRefresh(true);
      }
    }
  }
  const handleDetail = (id) => {
    localStorage.setItem("challenge_id", id)
    navigate(ROUTES.CREATER_CHALLENGES_DETAIL)
  }
  return (
    <div className="main-inner-content">
      {isLoading && <ReactLoader />}
      <section className="choose-wrapper">
        <div className="container">
          <div className="row align-items-center py-3">
            <div className="col-sm-5">
              <div className="">
                <h3 className="fw-600">Choose Challenges</h3>
              </div>
            </div>
            <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
              <button className={selectedList?.length > 0 ? "common-btn" : "common-btn-grey"} onClick={handleSubmit} style={{ cursor: selectedList.length > 0 ? "pointer" : "not-allowed" }}>Submit</button>            </div>
          </div>
          <div className='row'>
            {
              data?.length > 0 ? <>{
                data.map((item) => (
                  <div className=" col-lg-6 col-xxl-4 mb-3" style={{ cursor: 'pointer' }}>

                    <div className="channel-bites-content ">
                      <div className='position-relative'>
                        <img src={item.thumbnail} className="channelbite-img" alt="" />

                      </div>
                      <div className="bite-wrapper position-relative ">
                        <h4>{item.title}</h4>
                        <div className="chanel-text d-flex gap-3 align-items-center">
                          <img src={item.channelData.profile_pic} className="bite-inner-img" alt="" />
                          <div>
                           
                            <h6 className='fw-600 mb-0'>{item?.channelData?.name ? item?.channelData?.name : profile_image_placeholder}</h6>
                    <p className='mt-1'>{item?.category?.name}</p>
                          </div>
                        </div>
                        <button className="common-btn" onClick={() => handleDetail(item.id)}> View Detail</button>
                      </div>
                      <div className="roundcheckbox" >
                        <input
                          type="checkbox"
                          id={`checkbox-${item.id}`}
                          checked={selectedList.includes(item.id)}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                        <label htmlFor={`checkbox-${item.id}`}></label>
                      </div>
                    </div>
                  </div>
                ))
              }</> : <div style={{ flexDirection: 'column' }} className='no-search-results'>
                <img src={noChallenges} alt="No Challenges" />
              </div>
            }

          </div>
        </div>
      </section>
    </div>

  )
}
export default ChooseChallenges