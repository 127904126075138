import React, { useState, useEffect } from 'react'
import { modalCross } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import AddImageModal from './addImageModal'
import DeleteShopModal from './deleteModal'
import ImageShowModal from './imageShow'

const AddDetailModal = ({ setDetailModal, deltId, setTabSwitch, getDeleteAPI, deleteModal, setDeleteModal }) => {
    const [imagesList, setimagesList] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [addImageModal, setAddImageModal] = useState(false)
    const [imageShow, setImageShow] = useState(false)
    const[url,setUrl] = useState("")

    async function getDetailAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/get-shop-media?shopType=image&shop_id=${deltId}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setimagesList(result.data?.filter(item => item.id == deltId))
        }  
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }
    
    useEffect(() => {
        getDetailAPI()
    }, [refresh])

    console.log(imagesList,"dfghj")
    return (
        <>
            {
                loading && <ReactLoader />
            }
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-lg  modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Detail</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setDetailModal(false)
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <form className='price-form-input'>
                                <div className='d-flex justify-content-between'>
                                    <div>                                <h4>{imagesList[0]?.title}</h4>
                                        <h6 className="images-count">
                                            {imagesList[0]?.shop_media?.length} {imagesList[0]?.shop_media?.length == 1 ? "Image" : "Images"}
                                        </h6>
                                    </div>
                                    <div className='detail-main'><span className='first-price-detail'>${imagesList[0]?.price}</span>
                                    {/* <span className='second-price-detail'>00</span> */}
                                    </div>
                                </div>
                                <p className="image-detail-description" >
                                    {imagesList[0]?.description}                                                 </p>
                                <h4>Images</h4>
                                <div className='d-flex align-items-start justify-content-center justify-content-sm-start gap-3 flex-wrap overflow-scroll' style={{ maxHeight:350 }}>
                                    {
                                        imagesList[0]?.shop_media?.length > 0 && imagesList[0]?.shop_media.map((item) => (
                                            <div className='multi-images' 
                                            style={{ cursor: "pointer" }}  onClick={()=>{
                                                setImageShow(true)
                                                setUrl(item?.media_url)
                                            }}><img src={item.media_url}></img>
                                            </div>
                                        ))
                                    }
                                </div>
                                <button className='mt-4 price-submit-btn' onClick={(e) => {
                                    e.preventDefault()
                                    setAddImageModal(true)
                                }}>Edit</button>
                                <div className='mt-3 mb-2 delete-detail-image'
                                    style={{ cursor: "pointer" }} onClick={() => setDeleteModal(true)}>Delete</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                deleteModal && <DeleteShopModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check="image" />
            }
            {
                addImageModal && <AddImageModal setTabSwitch={setTabSwitch} setAddImageModal={setAddImageModal} deltId={deltId} setDetailModal={setDetailModal} />
            }
            {
                imageShow && <ImageShowModal setImageShow={setImageShow} url={url} />
            }
        </>
    )
}

export default AddDetailModal
