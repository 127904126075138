import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../../src/Helper/config";
import ReactLoader from '../../ControlComponents/react-loader';
import GiftSeries from './giftSeries';
import GiftRecorded from './giftRecorded';
import GiftUpcoming from './giftUpcoming';
import GiftImages from './giftImages';
import GiftAudio from './giftAudio';
import GiftVideo from './giftVideo';
import { useLocation } from 'react-router-dom';

function GiftContent() {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [seriesList, setSeriesList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [recoringList, setRecordingList] = useState([]);
    const [upcomingList, setUpcomingList] = useState([]);
    const [audiosList, setAudiosList] = useState([]);
    const [videosList, setVideosList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation()
    async function getListAPI() {
        setLoading(true);
        let url;
        if (localStorage.getItem("role") == 2) {
            url = `creator/get-users-gift-history?user_id=${location.state.id}&module_type=${tabSwitch == 0 ? "1" : tabSwitch == 1 ? "2" : tabSwitch == 2 ? "3" : tabSwitch == 3 ? "4" : tabSwitch == 4 ? "5" : "6"}`
        }
        else {
            url = `tipsAndGifts/get-gifts-history?channel_id=${location.state.id}&module_type=${tabSwitch == 0 ? "1" : tabSwitch == 1 ? "2" : tabSwitch == 2 ? "3" : tabSwitch == 3 ? "4" : tabSwitch == 4 ? "5" : "6"}`
        }
        try {
            let result = await apiHelper.getRequest(url);
            if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
                switch (tabSwitch) {
                    case 0:
                        setSeriesList(result.data);
                        break;
                    case 1:
                        if(localStorage.getItem("role") == 1){
                            setRecordingList(result.data);
                        }
                        else{
                            setRecordingList(result.data.history);
                        }
                        break;
                    case 2:
                        if(localStorage.getItem("role") == 1){
                            setUpcomingList(result.data);
                        }
                        else{
                            setUpcomingList(result.data.upcoming);
                        }
                        break;
                    case 3:
                        setImagesList(result.data);
                        break;
                    case 4:
                        setAudiosList(result.data);
                        break;
                    case 5:
                        setVideosList(result.data);
                        break;
                    default:
                        break;
                }

            } else if (result.code === 401) {
                let refreshToken = localStorage.getItem("refresh-token");
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                };
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);

                if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token);
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                    setRefresh(true);
                } else {
                    console.error("Failed to refresh token", refreshTokenResult);
                }
            } else {
                console.error("Unexpected result code", result);
            }
        } catch (error) {
            console.error("Error in getListAPI", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getListAPI();
    }, [tabSwitch, refresh]);

    return (
        <div className='main-inner-content'>
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Gifts</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="favchannel-main-cus">
                <section className="streaming py-6 response-padding">
                    {loading && <ReactLoader />}
                    {
                        tabSwitch === 0 ?
                            <GiftSeries detailList={seriesList} setTabSwitch={setTabSwitch} getListAPI={getListAPI} tabSwitch={tabSwitch} loading={loading} setLoading={setLoading} /> :
                            tabSwitch === 1 ?
                                <GiftRecorded detailList={recoringList} setLoading={setLoading} setTabSwitch={setTabSwitch} getListAPI={getListAPI} loading={loading} /> :
                                tabSwitch === 2 ?
                                    <GiftUpcoming setTabSwitch={setTabSwitch} detailList={upcomingList} getListAPI={getListAPI} loading={loading} setLoading={setLoading} /> :
                                    tabSwitch === 3 ?
                                        (<GiftImages setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} getListAPI={getListAPI} loading={loading} detailList={imagesList} setLoading={setLoading} />)
                                        :
                                        tabSwitch === 4 ?
                                            <GiftAudio setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} getListAPI={getListAPI} loading={loading} detailList={audiosList} setLoading={setLoading} /> :
                                            <GiftVideo setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} getListAPI={getListAPI} loading={loading} detailList={videosList} setLoading={setLoading} />
                    }
                </section>
            </section>
        </div>
    )
}

export default GiftContent;
