import React, { useState } from 'react'
import { buy_btn, DEMO_IMAGE_SEVEN, playBtn, playLightbtn } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'
import AddDetailVideoModal from '../../Pages/UserShop/detailVideoModal'

function GiftVideo({ setTabSwitch, detailList, loading, setLoading, getListAPI, check }) {
    const [deltId, setDeltId] = useState(null)
    const [addDetailVideoModal, setAddDetailVideoModal] = useState(false)

    const handleClick = (item) => {
        setDeltId(item.id)
        setAddDetailVideoModal(true)
    }

    return (
        <>
            {loading && <ReactLoader />}
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                {
                                    detailList?.reverse()?.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <img
                                                            src={item.shop_media[0]?.media_thumbnail}
                                                            className="img-fluid latest-vid-img new-latest-video"
                                                            alt="thumbnail"
                                                        />
                                                     
                                                    </div>
                                                </div>
                                                <div className="px-3 py-3 schedule-grid">
                                                    <div className=''>
                                                        <div className="tooltip-container">
                                                            <h4 className="schedule-title">{item.title?.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <h6 className="images-count">
                                                            {item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}
                                                        </h6>
                                                        <p className="images-description shop-description-detail">{item.description}</p>
                                                      

                                                            <span style={{ cursor: "pointer" }} onClick={() => handleClick(item)} className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <>
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={DEMO_IMAGE_SEVEN} alt="No Challenges" />
                                        {/* <img src={no_videos} alt="no videos" />
                                        <h6 className='position-absolute' style={{ bottom: 21 }}>No Videos yet</h6> */}
                                    </div>
                                    <h6 className='text-center' >No videos yet</h6>

                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>

                {
                    addDetailVideoModal && <AddDetailVideoModal setAddDetailVideoModal={setAddDetailVideoModal} setTabSwitch={setTabSwitch} deltId={deltId} />
                }
               
            </div>
        </>
    )
}

export default GiftVideo