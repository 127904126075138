import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DEMO_IMAGE_EIGHT, SCHEDULE_DOT, live_calender, live_clock, no_live } from '../../Constants/images'
import { Dropdown } from 'react-bootstrap'
import PriceTag from '../component/price-tag'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import DeleteShopModal from './deleteModal'
import EditModal from './editModal'
import { NotificationManager } from 'react-notifications'
import ROUTES from '../../Helper/route-helper'

function LivesShop({ setTabSwitch }) {
    const navigate = useNavigate()
    const [liveList, setLiveList] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const [price, setPrice] = useState("")

    const handleEdit = (item) => {
        setDeltId(item.id)
        setPrice(item.price)
        setEditOpen(true)
    }

    async function getDeleteAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("shop/remove-recording-price?id=" + deltId)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setDeleteModal(false)
            NotificationManager.success(result.message)
            getLivesAPI()
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
        else {
            NotificationManager.error(result.message)

        }
    }

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }

    const handleDelete = (id) => {
        setDeltId(id)
        setDeleteModal(true)
    }


    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        // originalDateTime.setHours(originalDateTime.getHours() + 5);
        // originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    async function getLivesAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/get-schedules?type=1")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setLiveList(result?.data?.history)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getLivesAPI()
    }, [refresh])

    const handleClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item))
        navigate(ROUTES.CREATER_SHOP_RECORDED_VIDEO)
    }

    return (
        <>
            {loading && <ReactLoader />}

            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {liveList?.length > 0 ? (
                            <>
                                {liveList?.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                        key={item?.id}
                                    >
                                        <div className="latest-video mx-auto" >
                                            <div className='position-relative'
                                            >
                                                <div
                                                    className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" onClick={() => handleClick(item)}
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={item.thumbnail}
                                                        className="img-fluid latest-vid-img new-latest-video"
                                                        alt="thumbnail"
                                                    />
                                                    <PriceTag classes={"price_tag"} firstValue={`$${item.recording?.price}`} secondValue={"00"} />
                                                </div>
                                            </div>
                                            <div className="latest-video-cus px-3 py-3 schedule-grid" >
                                                <div className='d-flex flex-column justify-content-start' onClick={() => handleClick(item)}>
                                                    <div className='tooltip-container'>
                                                        <h4 className="schedule-title">{item.name} </h4>                                              <span className="tooltip-text">{item.name}</span>
                                                    </div>
                                                    <div className="timing-flex">
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_calender} alt="calendar" />
                                                            <span className='ps-1'>
                                                                {dateFunction(item?.date_time)}
                                                            </span>
                                                        </div>
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_clock} alt="clock" />
                                                            <span className='ps-1'>
                                                                {addTime(item?.date_time)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dotschedule bg-transparent edit-dots'>
                                                    <div className='dropdownschedule'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="custom-dropdown-toggle" style={{padding:'0px 0px '}}>
                                                                <img src={SCHEDULE_DOT} className='img-fluid' alt="dot" />
                                                            </Dropdown.Toggle>
                                                            {/* <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => handleOpen(item)}>Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleDeleteOpen(item.id)}>Delete</Dropdown.Item>
                                                                </Dropdown.Menu> */}
                                                            <Dropdown.Menu className='record-card-dropdown'>
                                                                <Dropdown.Item onClick={() => handleEdit(item)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDelete(item.recording.id)}>Remove</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : <>
                            {
                                loading == false &&
                                <>
                                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                            <img src={DEMO_IMAGE_EIGHT} alt="No Challenges" />
                                        </div>
                                        <h6 className='text-center'>No lives yet</h6>
                                    </>
                            }
                        </>
                        }
                    </div>
                </div>
                {
                    deleteModal && <DeleteShopModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check="live" />
                }
                {
                    editOpen && <EditModal iniPrice={price} deltId={deltId} getLivesAPI={getLivesAPI} setEditOpen={setEditOpen} check="live" />
                }
            </div>
        </>
    )
}

export default LivesShop