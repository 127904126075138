import React from 'react'
import { modalCross, show_audio } from '../../Constants/images'

const AudioPlayModal = ({ setAudioPlay, url
}) => {
    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width justify-content-center justify-content-md-start">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <a onClick={() => {
                            setAudioPlay(false)
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body forgot-body verify-modal-box px-2 px-sm-4">
                        <div className='delete-content text-center'>
                            <div className='position-relative'
                            >
                                <div

                                    className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                    style={{ cursor: "pointer" }}>
                                    <div className='show-audio_preview'><img
                                        src={show_audio}
                                    />
                                        <div>
                                            <audio controls src={url} autoPlay></audio>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default AudioPlayModal
