import React from 'react'
import DownloadDetail from './download-detail'
function Download() {

    return (
        <div className='main-inner-content'>
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Downloads</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="favchannel-main-cus">
                <DownloadDetail />
            </section>
        </div>

    )
}

export default Download
