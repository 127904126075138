import React, { useDebugValue, useEffect, useRef, useState } from 'react'
import ReactLoader from '../../ControlComponents/react-loader'
import Bites from '../DashboardHome/Components//Bites/Bites'
import LatestChallenge from '../DashboardHome/Components/LatestChallenge/LatestChallenge'
import NewTalent from '../DashboardHome/Components//NewTalent/NewTalent'
import Interactives from '../DashboardHome/Components/Intractives/Interactives'
import Categories from './Components/Categories/Categories'
import Channels from './Components/Channels/Channels'
import ComingSoon from './Components/ComingSoon/ComingSoon'
import DashHomeSlider from './Components/DashHomeSlider/DashHomeSlider'
import './dashboardHome.css'
import ShopHome from './Components/shop/shopHome'
import LiveHome from './Components/liveHome'

const DashboardHome = () => {
    const containerRef = useRef(null);
    const [category, setCategory] = useState(0);
    const [isLoading, setIsLoading] = useState(false)

   
    return (
        <>
            <div ref={containerRef}></div>
            <div className="main-inner-content">

                {isLoading && <ReactLoader />}
                <div style={{ opacity: 0, marginTop: -25 }}>00</div>
                <DashHomeSlider isLoading={isLoading} setIsLoading={setIsLoading} containerRef={containerRef} />
                <Categories isLoading={isLoading} setIsLoading={setIsLoading} setCategory={setCategory} category={category} />
                <Channels isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
                <Bites isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
                <LatestChallenge isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
                <LiveHome setIsLoading={setIsLoading} />
                <NewTalent isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
                <Interactives isLoading={isLoading} setIsLoading={setIsLoading} category={category} />
                <ShopHome setIsLoading={setIsLoading} />
                <ComingSoon category={category} />
            </div>
           
        </>
    )
}

export default DashboardHome