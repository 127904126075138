import React, { useEffect, useState } from "react";
import {live_calender, live_clock, no_live } from "../../Constants/images";
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from "../../ControlComponents/react-loader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";

const ChooseLives = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const tierItem = JSON.parse(localStorage.getItem('tierItem'))
    const [selectedList, setSelectedList] = useState([]);

    const handleCheckboxChange = (id) => {
        setSelectedList((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((seriesId) => seriesId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

  

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }


    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);

        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    async function handleSubmit() {
        if(selectedList?.length == 0) return
        setIsLoading(true);
        let data = {
          tier_id: tierItem.id,
          item_type: "2",
          item_id: selectedList,
        };
        let result = await apiHelper.postRequest("creator/add-tier-content", data);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
          setIsLoading(false);
          navigate(ROUTES.CREATER_BUCKET_ACCESS)
          localStorage.setItem('message', result.message + 'live')
    
        } else if (result.code === 401) {
          let refreshToken = localStorage.getItem("refresh-token");
          let data = {
            refresh_token: refreshToken,
            scope: "refresh_token",
          };
          let refreshTokenResult = await apiHelper.postRequest(
            "auth/refresh-token",
            data
          );
          if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token);
            localStorage.setItem(
              "refresh-token",
              refreshTokenResult.data.refresh_token
            );
            setRefresh(true);
          }
        }
      }

    const hanldeClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item))
        navigate(ROUTES.CREATER_RECORDED_VIDEO)
    }

    async function getAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/recorded-lives-list")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setData(result.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh])

    return (

        <div className="main-inner-content">
            {
                loading && <ReactLoader />
            }
            <section className="choose-wrapper">
                <div className="container">
                    <div className="row align-items-center py-3">
                        <div className="col-sm-5">
                            <div className="">
                                <h3 className="fw-600">Choose Recorded Lives</h3>
                            </div>
                        </div>
                        <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
                            <button className={selectedList?.length > 0 ? "common-btn" : "common-btn-grey"} onClick={handleSubmit} style={{ cursor: selectedList.length > 0 ? "pointer" : "not-allowed" }}>Submit</button>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        {data.length > 0 ? (
                            <>
                                {data.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                        key={item?.id}
                                    >
                                        <div className="latest-video mx-auto" >
                                            <div className='position-relative'
                                            onClick={()=>hanldeClick(item)}
                                            >
                                                <div
                                                    className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={item.thumbnail}
                                                        className="img-fluid latest-vid-img new-latest-video"
                                                        alt="thumbnail"
                                                    />
                                                </div>
                                            </div>
                                            <div className="latest-video-cus px-3 py-3 schedule-grid position-relative">
                                                <div className='' >
                                                    <h4 className="schedule-title">{item.name} </h4>
                                                    <div className="timing-flex">
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_calender} alt="calendar" />
                                                            <span className='ps-1'>
                                                                {dateFunction(item.start_time)}
                                                            </span>
                                                        </div>
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_clock} alt="clock" />
                                                            <span className='ps-1'>
                                                                {addTime(item.start_time)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="roundcheckbox tier-checkbox" style={{ cursor: "pointer", right: 42 }}>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-${item.id}`}
                                                        checked={selectedList.includes(item.id)}
                                                        onChange={() => handleCheckboxChange(item.id)}
                                                    />
                                                    <label htmlFor={`checkbox-${item.id}`}></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : <>
                            {
                                loading == false &&
                                (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={no_live} alt="no live" />
                                    </div>
                                )
                            }
                        </>
                        }
                    </div>

                </div>
            </section>
        </div>

    )
}
export default ChooseLives