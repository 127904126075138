import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from '../../ControlComponents/react-loader'
import MySeries from './mySeries';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import { NotificationManager } from 'react-notifications';
import { DEMO_IMAGE_FOUR } from '../../Constants/images';

const Series = () => {
    const navigate = useNavigate()
    const [favSeries, setFavSeries] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    let message = localStorage.getItem("message")

    useEffect(() => {
        if (message) {
            NotificationManager.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        }
    }, [])

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/my-series")

        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setFavSeries(result.data.allSeries)
            setIsLoading(false)
        }
        else if (result?.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [refresh])


    const handleCreate = () => {
        const id = null
        navigate(ROUTES.CREATER_CREATER_SERIES, { state: { id } })
    }
    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row align-items-center px-2'>
                    <div className='col-sm-5'>
                        <div className="">
                            <h3 className="fw-600">Series</h3>
                        </div>
                    </div>
                    <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                        <button className="common-btn" onClick={handleCreate}>Create Series</button>
                    </div>
                </div>
                {
                    favSeries.length <= 0 && isLoading == false ?
                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                            <img src={DEMO_IMAGE_FOUR} alt="No Challenges" />
                            <h6 className='position-absolute' style={{ top: 440 }}>No series yet</h6>                        </div>
                        :
                        <MySeries favSeries={favSeries} getDetailsAPI={getDetailsAPI} />
                }

            </div>
        </div>
    )
}

export default Series;