import React, { useState } from 'react'
import { buy_btn, DEMO_IMAGE_SIX, no_audio, playBtn, show_audio } from '../../Constants/images'
// import PriceTag from '../../CreaterDashboard/component/price-tag'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'
import AddDetailAudioModal from '../../Pages/UserShop/detailAudioModal'

function GiftAudio({ setTabSwitch, detailList, loading, setLoading, getListAPI, check }) {
    const [deltId, setDeltId] = useState(null)
    const [addDetailAudioModal, setAddDetailAudioModal] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})
    const navigate = useNavigate()
    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                               {(
                                    detailList.length > 0 && detailList?.reverse().map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <div className='show-audio'>
                                                            <img src={show_audio} alt="audio icon" />
                                                        </div>
                                                      
                                                    </div>
                                                    <div className="px-3 py-3 schedule-grid">
                                                        <div className=''>
                                                            <div className="tooltip-container">
                                                                <h4 className="schedule-title">{item.title}</h4>
                                                                <span className="tooltip-text">{item.title}</span>
                                                            </div>                                                            <h6 className="images-count">{item?.shop_media?.length} {item?.shop_media?.length === 1 ? "Audio" : "Audios"}</h6>
                                                            <p className="images-description shop-description-detail">{item.description}</p>
                                                            
                                                                <span style={{ cursor: "pointer" }} onClick={() => {
                                                                    setDeltId(item.id)
                                                                    setAddDetailAudioModal(true)
                                                                }} className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <>
                                        <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                            <img src={DEMO_IMAGE_SIX} alt="No Challenges" style={{ marginLeft: 79 }} />
                                         
                                        </div>
                                        <h6 className='position-absolute'>No audios yet</h6>
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
                {
                    addDetailAudioModal && <AddDetailAudioModal setAddDetailAudioModal={setAddDetailAudioModal} deltId={deltId} />
                }
              
            </div>
        </>
    )
}

export default GiftAudio