import React, { useEffect, useState } from 'react'
import Category from './Components/Category/Category'
import './discover.css'
import Recomended from './Components/Recomended/Recomended'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import MostPopular from './mostPopular'
import NewSeries from './newSeries'
import NewChallenges from './popularAction'
import NewBites from './newBites'
import FeatureSlider from './Components/featureSlider'


function Discover() {
  const [featuredList, setFeaturedList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("series/series-list?offset=1")
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      return setFeaturedList(result?.data)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getAPI()
  }, [refresh]);

  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      <Category/>
      {/* <Featured featuredList={featuredList} getAPI={getAPI}/> */}
      <FeatureSlider featuredList={featuredList} check="discover" getAPI={getAPI} setIsLoading={setIsLoading} />
      <Recomended featuredList={featuredList} getAPI={getAPI} setIsLoading={setIsLoading}/>
      <MostPopular featuredList={featuredList} getAPI={getAPI} setIsLoading={setIsLoading} />
      <NewSeries featuredList={featuredList} getAPI={getAPI} setIsLoading={setIsLoading} />
      <NewChallenges isLoading={isLoading} setIsLoading={setIsLoading}  />
      <NewBites isLoading={isLoading} setIsLoading={setIsLoading}  />
      {/* <NewUpcoming isLoading={isLoading} setIsLoading={setIsLoading}  /> */}
    </div>
  )
}

export default Discover;