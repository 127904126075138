import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { buy_btn, DEMO_IMAGE_FOUR, playBtn } from '../../Constants/images'
import ROUTES from '../../Helper/route-helper'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import BuyModal from '../../Components/PaymentModal/BuyModal'

function ShopSeries({ setTabSwitch, loading, detailList, channelImg, channelName, channelId, setLoading, check, getListAPI }) {

    const navigate = useNavigate()
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const handleNavigate = (id) => {
        if (check == "shop") {
            navigate(ROUTES.SHOP_SERIES_DETAIL, { state: { id: id } }
            )
        }
        else if (check == "download") {
            navigate(ROUTES.DOWNLOAD_SERIES_DETAIL, { state: { id: id } }
            )
        }
        localStorage.setItem("channelName", channelName)
        localStorage.setItem("channelImg", channelImg)
        localStorage.setItem("channelId", channelId)
    }

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div>
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length == 0 && loading === false ? (
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={DEMO_IMAGE_FOUR} alt="No Challenges" />
                                <h6 className='position-absolute' style={{ bottom: 15 }}>No Series yet</h6>
                            </div>
                        ) : (
                            <>
                                {check === "download" ? (
                                    detailList.filter(item => item.is_purchase === 1).length > 0 ? (
                                        detailList.filter(item => item.is_purchase === 1).map(item => (
                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols " key={item.id}>
                                                <div className="latest-video mx-auto">
                                                    <div className='position-relative'>
                                                        <div className="latest-video-content pt-2 pt-sm-3 px-2 px-sm-3">
                                                            <img src={item.thumbnail} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                            <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=liveChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                                <img src={item.channelIcon} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                                <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.channelName}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="latest-video-cus px-3 py-3">
                                                        <div className="tooltip-container">
                                                            <h4 className="fw-600 mb-0">{item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <div className="latest-series-name">
                                                            <div className="video-tile">
                                                                <p className='mb-0 fw-400'>{item.episodesCount} {item.episodesCount === 1 ? "Episode" : "Episodes"}</p>
                                                                <p className='mb-0 fw-500'>{item.year}</p>
                                                            </div>
                                                            <span style={{ cursor: "pointer" }} onClick={() => handleNavigate(item.id)} className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <>
                                            {
                                                loading == false &&
                                                <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                                    <img src={DEMO_IMAGE_FOUR} alt="No Challenges" />
                                                    <h6 className='position-absolute' style={{ bottom: 15 }}>No Series yet</h6>
                                                </div>
                                            }
                                        </>

                                    )
                                ) : (
                                    detailList.filter(item => item.price != "0.00")?.length > 0 && detailList?.filter(item => item.price != "0.00").map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content pt-2 pt-sm-3 px-2 px-sm-3">
                                                        <img src={item.thumbnail} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                        {item.is_purchase === 0 && <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${item.price}`} secondValue={"00"} />}
                                                        <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=liveChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                            <img src={item.channelIcon} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                            <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.channelName}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                    <div className="grid-data-detail-lives position-relative w-100">
                                                        <div className="tooltip-container">
                                                            <h4 className="fw-600 mb-0">{item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <div className="latest-series-name">
                                                            <div className="video-tile">
                                                                <p className='mb-0 fw-400'>{item.episodesCount} {item.episodesCount === 1 ? "Episode" : "Episodes"}</p>
                                                                <p className='mb-0 fw-500'>{item.year}</p>
                                                            </div>
                                                            {item.is_purchase === 0 ? (
                                                                <span style={{ cursor: 'pointer' }} className='text-end' onClick={() => handleOpen(item)}>
                                                                    <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                </span>
                                                            ) : (

                                                                <span style={{ cursor: "pointer" }} onClick={() => handleNavigate(item.id)} className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        )}
                    </div>

                    {buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} itemType="1" getApi={getListAPI} />}
                </div>
            </div >
        </>
    )
}

export default ShopSeries