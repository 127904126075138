import React, { useState } from 'react'
import { modalCross } from '../../Constants/images'
import { InputAdornment, TextField } from '@mui/material'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import { NotificationManager } from 'react-notifications'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../PaymentModal/payement'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
 
const TipModal = ({ setTipOpen, userId, getApi, check
}) => {
    const [price, setPrice] = useState(null);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessage1, setErrorMessage1] = useState("");
    const [isLoading, setLoading] = useState(false)
    const [next, setNext] = useState(false)
    function changeHandler(e) {
        setErrorMessage("")
        setErrorMessage1("")
        setPrice(e.target.value);
    }


    async function postTip(transactionId, status) {
        setLoading(true);
        let data = {
            "channel_id": userId,
            "amount": parseInt(price?.slice(0, 3)),
            "message": message,
            "mode_of_payment": "credit_card",
            "transaction_id": transactionId,
            "payment_status": status == "succeeded" ? 1 : 0
        }
        try {
            const result = await apiHelper.postRequest("tipsAndGifts/give-tip", data);
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                NotificationManager.success(result.message)
                setLoading(false)
                setTipOpen(false)
                if (check == 'history') {
                    getApi()
                }
            }
            else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

   
    const handleNext = () => {
        if (!price || price.trim() === "") {
            setErrorMessage("Enter price");
        }
        if (!message || message.trim() === "") {
            setErrorMessage1("Enter message");
        }
        else{
            setNext(true)
        }
    }
    return (

        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            {
                isLoading && <ReactLoader />
            }
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                {
                    next == false ?
                    <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-5">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Tip</h3>
                        <a onClick={() => {
                            setTipOpen(false)
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                        <form className='price-form-input' >
                            <label className='fw-500 f18-size text-black pb-2'>Tip Amount</label>

                            <TextField
                                className='price-input'
                                type='number'
                                min="1"
                                value={price?.slice(0, 3)}
                                onChange={changeHandler}
                                placeholder='Enter Price'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            {errorMessage && <span className="err_msg">{errorMessage}</span>}
                            <div className="form-group mb-3 mt-3">
                                <label className="fw-500 f18-size text-black pb-2">Message</label>
                                <textarea style={{ height: 100 }} className="fw-500 forgot-input place-grey d-block w-100" placeholder="Enter Here" rows="40" value={message} onChange={(e) => {
                                    setErrorMessage('')
                                    setErrorMessage1("")
                                    setMessage(e.target.value)
                                }

                                } ></textarea>
                                {errorMessage1 && <span className="err_msg">{errorMessage1}</span>}
                            </div>

                            <button type='button' className='price-submit-btn mb-5 mt-2' onClick={handleNext} >Next</button>
                        </form>
                    </div>
                </div>:
                  <div className="modal-content modal-radius  border-0">
                  <div className="modal-header forgot-header align-items-center pt-4">
                      <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Payment Details</h3>
                      <a onClick={() => {
                          setTipOpen(false)
                      }}
                          className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                  </div>
                  <div className="modal-body  verify-modal-box px-2 px-sm-5">
                      <Elements stripe={stripePromise}>

                          <PaymentForm price={price} setLoading={setLoading} buyApi={postTip} />
                      </Elements>

                  </div>
              </div>
                }
               
            </div>
        </div>

    )
}

export default TipModal
