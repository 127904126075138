import React, { useEffect, useRef, useState } from 'react'
import Episode from './Components/Episode/Episode'
import MoreMuvi from './Components/MoreMuvi/MoreMuvi'
import Trailor from './Components/Trailor/Trailor'
import VideoBanner from './Components/VideoBanner/VideoBanner'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import './frame.css'
import UserSeriesChat from './userSeriesChat'
import BiteSeriesUser from './biteSeriesUser'
import CastAndCrew from './Components/cast/cast'
import RecommendSeriesFrame from './Components/recommend'
import ROUTES from '../../Helper/route-helper'
import BuyModal from '../../Components/PaymentModal/BuyModal'
function Frame() {
  const location = useLocation()
  const navigate = useNavigate()
  const { seriesId } = useParams()
  const [seriesDetail, setSeriesDetail] = useState(null)
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState("")
  const [seriesDetailNew, setSeriesDetailNew] = useState()
  const [activeComponent, setActiveComponent] = useState(0);
  const [id, setId] = useState(seriesId == undefined ? location?.state?.id : seriesId)
  const [chatOpen, setChatOpen] = useState(false)
  const [creatorName, setCreatorName] = useState(null)
  const [creatorImg, setCreatorImg] = useState(null)
  const [moreSeries, setMoreSeries] = useState([])
  const [recommend, setRecommend] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [_, forceUpdate] = useState(0)
  const [click, setClick] = useState(false)
  const containerRef = useRef(null);

  async function getMyDetailsAPI() {
    let result = await apiHelper.getRequest("auth/get-my-detail")
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setCreatorName(result?.data?.name);
      setCreatorImg(result.data?.profile_pic)
    } else if (result?.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult?.data?.token)
        localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("series/series-detail?id=" + id)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      
        if (result.data.series.tier_details == null) {
          if (result.data.series.is_purchase == 0) {
            setItem(result.data.series)
            setOpen(true)
          }
          else {
            setSeriesDetail(result.data.series)
            setMoreSeries(result.data.moreSeries)
            setSeriesDetailNew(result.data)
            setRecommend(result.data.recommend)
            const trimmedName = result?.data?.series?.title?.replace(/\s/g, '');;
            const chatId = `${result?.data?.series?.id}${trimmedName}`;
            localStorage.setItem("userChatId", chatId)
            setChatOpen(true)
          }
        }
        else {
          if (result.data.series.tier_details.is_purchased == 0) {
            localStorage.setItem('tierContentId', result.data.series.id + 'series')
            navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: result.data.series.tier_details.tier_id } })
          }
          else {
            setSeriesDetail(result.data.series)
            setMoreSeries(result.data.moreSeries)
            setSeriesDetailNew(result.data)
            setRecommend(result.data.recommend)
            const trimmedName = result?.data?.series?.title?.replace(/\s/g, '');;
            const chatId = `${result?.data?.series?.id}${trimmedName}`;
            localStorage.setItem("userChatId", chatId)
            setChatOpen(true)
  
          }
  
        }
      
      
      setIsLoading(false)

    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }


  useEffect(() => {
    getDetailsAPI()
    getMyDetailsAPI()
    if (containerRef.current && click) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
      setClick(false)
    }
  }, [refresh, id])

  function modifyDuration(duration) {
    const durationParts = duration?.split(':');
    if (durationParts && durationParts[0] === '00') {
      return durationParts.slice(1)?.join(':');
    }
    return duration;
  }

  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  return (
    <div ref={containerRef}>
      <div className="main-inner-content" >
        {isLoading && <ReactLoader />}
        <VideoBanner seriesDetail={seriesDetail} addFavourites={addFavourites} setIsLoading={setIsLoading} id={id} />
        <div style={{ display: 'flex', flexDirection: 'row', gap: 15, cursor: "pointer" }} className='container'>
          <div className="stream-btn white-height">
            <button className={activeComponent == 0 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(0)}>Episodes</button>
            <button className={activeComponent == 1 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(1)}>Cast & Crew</button>
            <button className={activeComponent == 2 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(2)}>Bites</button>
            <button className={activeComponent == 3 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(3)}>Trailor & Clips</button>
            <button className={activeComponent == 4 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(4)}>Comments</button>
          </div>
        </div>
        {activeComponent === 0 && <Episode seriesDetail={seriesDetail} modifyDuration={modifyDuration} chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />}
        {activeComponent === 1 && <CastAndCrew seriesDetail={seriesDetail} />}
        {activeComponent === 2 && <BiteSeriesUser seriesDetail={seriesDetail} />}
        {activeComponent === 3 && <Trailor seriesDetail={seriesDetail} modifyDuration={modifyDuration} />}
        {activeComponent === 4 && <UserSeriesChat chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />}
        <MoreMuvi setId={setId} seriesNew={seriesDetail} moreSeries={moreSeries} getDetailsAPI={getDetailsAPI} setIsLoading={setIsLoading} setClick={setClick} />
        <RecommendSeriesFrame setId={setId} seriesNew={seriesDetail} recommend={recommend} getDetailsAPI={getDetailsAPI} setIsLoading={setIsLoading} setClick={setClick} />
      </div>
     
        {open && <BuyModal item={item} setLoading={setIsLoading} setBuyModal={setOpen} itemType="1" getApi={getDetailsAPI} />}
    </div>
  )
}

export default Frame;