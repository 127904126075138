import React, { useEffect, useState } from "react";
import { DEMO_IMAGE_SEVEN } from "../../Constants/images";
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from "../../ControlComponents/react-loader";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";
import AddDetailVideoModal from "../../Pages/UserShop/detailVideoModal";

const VideoGift = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [data, setData] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [selectedList, setSelectedList] = useState([]);
    const [deltId, setDeltId] = useState(null)
    const [addDetailVideoModal, setAddDetailVideoModal] = useState(false)
    
    const handleCheckboxChange = (id) => {
        setSelectedList((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((seriesId) => seriesId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };


    async function handleSubmit() {
        if (selectedList?.length == 0) return
        setIsLoading(true);
        let data = {
            user_id: location.state.id,
            module_type: "6",
            item_ids: selectedList,
        };
        let result = await apiHelper.postRequest("creator/send-gifts", data);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false);
            navigate(ROUTES.TIPS_CREATOR)
            localStorage.setItem("message", result.message)
        } else if (result.code === 401) {
            let refreshToken = localStorage.getItem("refresh-token");
            let data = {
                refresh_token: refreshToken,
                scope: "refresh_token",
            };
            let refreshTokenResult = await apiHelper.postRequest(
                "auth/refresh-token",
                data
            );
            if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token);
                localStorage.setItem(
                    "refresh-token",
                    refreshTokenResult.data.refresh_token
                );
                setRefresh(true);
            }
        }
    }

    const hanldeClick = (item) => {
        setDeltId(item.id)
        setAddDetailVideoModal(true)
    }

    async function getAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/get-shop-for-gift?user_id=${location.state.id}&shopType=video`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setData(result.data)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh])

    return (

        <div className="main-inner-content">
            {
                loading && <ReactLoader />
            }
            <section className="choose-wrapper">
                <div className="container">
                    <div className="row align-items-center py-3">
                        <div className="col-sm-5">
                            <div className="">
                                <h3 className="fw-600">Choose Video</h3>
                            </div>
                        </div>
                        <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
                            <button className={selectedList?.length > 0 ? "common-btn" : "common-btn-grey"} onClick={handleSubmit} style={{ cursor: selectedList.length > 0 ? "pointer" : "not-allowed" }}>Send</button>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        {data.length > 0 ? (
                            <>
                                {data.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                        key={item?.id}
                                    >
                                        <div className="latest-video mx-auto" >
                                            <div className='position-relative'
                                                onClick={() => hanldeClick(item)}
                                            >
                                                <div
                                                    className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={item.shop_media[0].media_thumbnail}
                                                        className="img-fluid latest-vid-img new-latest-video"
                                                        alt="thumbnail"
                                                    />

                                                </div>
                                            </div>
                                            <div className="latest-video-cus px-3 py-3 schedule-grid position-relative">
                                                <div className='' >
                                                    <div className='tooltip-container'>
                                                        <h4 className="schedule-title">{item.title} </h4>                                              <span className="tooltip-text">{item.title}</span>
                                                    </div>                                                    <h6 className="images-count">

                                                        {item.shop_media.length} {item.shop_media.length == 1 ? "Video" : "Videos"}
                                                    </h6>
                                                    <p className="images-description" >
                                                        {item.description}
                                                    </p>
                                                </div>
                                                <div className="roundcheckbox tier-checkbox" style={{ cursor: "pointer", right: 42 }}>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-${item.id}`}
                                                        checked={selectedList.includes(item.id)}
                                                        onChange={() => handleCheckboxChange(item.id)}
                                                    />
                                                    <label htmlFor={`checkbox-${item.id}`}></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : <>
                            {
                                loading == false &&
                                <>    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                    <img src={DEMO_IMAGE_SEVEN} alt="No Challenges" />

                                </div>
                                    <h6 className='text-center mx-5'>No videos yet</h6></>
                            }
                        </>
                        }
                    </div>
                    {
                        addDetailVideoModal && <AddDetailVideoModal setAddDetailVideoModal={setAddDetailVideoModal} deltId={deltId} />
                    }
                </div>
            </section>
        </div>

    )
}
export default VideoGift