
import React, { useMemo, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import "./payment.css"
import apiHelper from "../../Helper/api-helper";
import { NotificationManager } from "react-notifications";
const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize: "19px",
                    color: "grey",
                    letterSpacing: "0.025em",
                },
                invalid: {
                    color: "grey",
                },
            },
            autocomplete: "off",
        }),
        []
    );

    return options;
};


const PaymentForm = ({ price, setLoading, buyApi }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [errors, setErrors] = useState({
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            console.error("Stripe.js has not loaded yet.");
            return;
        }
        const { error } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
        });

        if (error) {
            console.error("Error:", error);
        } else {
            setLoading(true)
            let data = {
                amount: Math.floor(price)
            };


            let result = await apiHelper.postService("payment/create-payment-intent", data);
            const clientSecret = result.data?.clientSecret;

            if (!clientSecret) {
                console.error("Client secret not received from backend.");
                return;
            }

            const cardElement = elements.getElement(CardNumberElement);
            if (!cardElement) {
                console.error("Card element not found.");
                return;
            }

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (error) {
                console.error("Payment failed:", error.message);
                NotificationManager.error(error.message)
            } else if (paymentIntent.status === "succeeded" || paymentIntent.status === "pending") {
                // console.log("Payment successful!", paymentIntent);
                buyApi(paymentIntent?.id, paymentIntent.status)
            }
        }


    };

    const handleCardChange = (field, event) => {

        if (event.error) {
            setErrors((prev) => ({ ...prev, [field]: event.error.message }));
        } else {
            setErrors((prev) => ({ ...prev, [field]: null }));
        }
    };



    return (


        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="mb-4">
                <label className="fw-500 f18-size text-black mb-2">
                    Card number                            </label>

                <CardNumberElement
                    options={{ ...options, disableLink: true }}
                    onChange={(event) => handleCardChange("cardNumber", event)}
                />
                {errors.cardNumber && <div style={{ color: "red" }}>{errors.cardNumber}</div>}
            </div>

            <div className="mb-4">
                <label className="fw-500 f18-size text-black mb-2">                                Expiration date                             </label>

                <CardExpiryElement
                    options={{ ...options, disableLink: true }}
                    onChange={(event) => handleCardChange("cardExpiry", event)}
                />
                {errors.cardExpiry && <div style={{ color: "red" }}>{errors.cardExpiry}</div>}
            </div>
            <div className="mb-4">
                <label className="fw-500 f18-size text-black mb-2">
                    CVC                             </label>

                <CardCvcElement
                    options={{ ...options, disableLink: true }}
                    onChange={(event) => handleCardChange("cardCvc", event)}
                />
                {errors.cardCvc && <div style={{ color: "red" }}>{errors.cardCvc}</div>
                }
            </div>
            <div className=' d-flex justify-content-center live-start-btn mb-3'>

                <button className="common-btn" type="submit" disabled={!stripe}>
                    Pay
                </button>
            </div>
        </form>

    );
};

export default PaymentForm;
