import React, { useEffect, useRef, useState } from 'react';
import { pink_dots, profile_image_placeholder } from '../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import './createrSubscriber.css'
import ReactLoader from '../../ControlComponents/react-loader';
import { Dropdown } from 'react-bootstrap';

function CreaterSubscribers() {
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [follower, setFollower] = useState([])
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const loadMoreRef = useRef(null);
  const [scroll, setScroll] = useState(false)
 
  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest(`creator/followers-list?offset=${page}&limit=${12}`)
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setIsLoading(false)
      if (page == 1) {
        setFollower(result?.data?.followers)
      } else {
        setFollower((prevBites) => [...prevBites, ...result?.data?.followers]);
      }
      setHasMoreItems(result?.data?.followers.length == 12);
      
    }
    else if (result?.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  const handleLoadMore = () => {
    setScroll(true)
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    getAPI()
  }, [refresh, page])

  useEffect(() => {
    if (loadMoreRef.current && scroll) {
      loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setScroll(false)
    }
  }, [follower, scroll]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      {
        isLoading &&
        <ReactLoader />
      }
      <div className="main-inner-content">
        <section className="favourite-cus bite response-padding ">
          <div className="container">
            <div className="chanel-header d-flex align-items-center justify-content-between">
              <h3 className="fw-600 mb-0">Followers</h3>
              <div className="col-lg-4 ">
                <form>
                  <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                  </div>
                </form>
              </div>
            </div>
            <section className="py-60  favchannel-main-cus">
              <div className="container-fluid">
                <div className="row">
                  {
                    follower?.map((item) => (
                      <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                        <div className="channels-box  position-relative position-relative mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                          <img src={item?.profile_pic ? item?.profile_pic : profile_image_placeholder} className="img-fluid  subscribe-img" />
                          <div className='dotschedule bg-transparent edit-dots plan-follower'>
                            <div className='dropdownschedule'>
                              <Dropdown>
                                <Dropdown.Toggle id="custom-dropdown-toggle">
                                  <img src={pink_dots} className='img-fluid' alt="dot" style={{height:28}}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='record-card-dropdown view-drop' >
                                  {
                                    item?.tiers_data?.map((item) => (
                                      <div className='w-100 item-follow '  >
                                        <p className="lh-0 mb-1 text-nowrap">
                                          {item?.plan_name}
                                        </p>
                                        <p className=" mb-1">
                                          {formatDate(item.expiry_date)}
                                        </p>

                                        <div className=" mb-1">{item.price == "0.00" ? "Free" : "$" + item.price}</div>
                                      </div>
                                    ))
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <h4 className="channel-text fw-600 mt-3 mb-0"> {item?.name}</h4>
                          {/* <p className="channel-para mb-2"><i>Get Access with Combo</i></p>
                          <h5 className='price-subs mb-3'>$150.<span className='f17-size'>00</span></h5>
                          <span className='subs-date mb-0 f18-size fw-500 channel-text'>Expiry date :<span className='video-text'> 31-10-2023</span></span> */}
                        </div>
                      </div>
                    ))
                  }
                  {
                    hasMoreItems && <div style={{ cursor: 'pointer' }} onClick={handleLoadMore} className="load-text mb-3">
                      Load More...
                    </div>
                  }
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CreaterSubscribers;