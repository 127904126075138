import React, { useEffect, useRef, useState } from "react";
import "./socialFeeds.css";
import {
    Send_feed, dotshorizontal, message, galleryImg,
    favRed,
    favouritesDark,
    Righticons,
    Lefticons,
    pinkLeftIcons,
    pinkRightIcons,
    profile_image_placeholder
} from "../../Constants/images";
import ShareModalCreator from "./shareModalCreator";
import PostModal from "./postModal";
import DetailModal from "./detailModal";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from "../../ControlComponents/react-loader";
import { Dropdown } from "react-bootstrap";
import DeletePost from "./deletePost";
import { NotificationManager } from "react-notifications";

const SocialFeeds = () => {
    const [page, setPage] = useState(1);
    const loadMoreRef = useRef(null);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const [id, setId] = useState("")
    const [feeds, setFeeds] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false)
    const [editPost, setEditPost] = useState(false)
    const [shareModal, setShareModal] = useState(false);
    const [postModal, setPostModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [userDetail, setUserDetail] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [_, forceUpdate] = useState(0)
    const [mediaIndexMap, setMediaIndexMap] = useState({});
    const handleNext = (postId, postDetailsLength) => {
        setMediaIndexMap(prevState => ({
            ...prevState,
            [postId]: ((prevState[postId] || 0) + 1) % postDetailsLength
        }));
    };
    
    const handlePrevious = (postId, postDetailsLength) => {
        setMediaIndexMap(prevState => ({
            ...prevState,
            [postId]: ((prevState[postId] || 0) - 1 + postDetailsLength) % postDetailsLength
        }));
    };
    const hasPrevious = (feedId) => (mediaIndexMap[feedId] || 0) > 0;
    const hasNext = (feedId, postDetailsLength) => (mediaIndexMap[feedId] || 0) < postDetailsLength - 1

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    async function addFavourites(item) {
        if (item.is_liked == 0) {
            item.is_liked = 1
            item.total_likes = item.total_likes + 1;
        } else {
            item.is_liked = 0
            item.total_likes = item.total_likes - 1;
        }
        forceUpdate(Math.random())
        let result = await apiHelper.getRequest("post/like-dislike-posts?post_id=" + item.id)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
            // getPostAPI()
        }
    }

    async function getDetailsAPI() {
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setUserDetail(result.data);
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [refresh])


    async function getPostAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`post/my-posts-list?offset=${page}&limit=10`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            const newBites = result?.data.data;
            if (page === 1) {
                setFeeds(result?.data.data);
            } else {
                setFeeds((prevBites) => [...prevBites, ...newBites]);
            }
            setHasMoreItems(newBites.length === 10);
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getPostAPI()
    }, [refresh, page])

    const date = (time) => {
        const dateObj = new Date(time);
        if (isNaN(dateObj)) {
            console.error("Invalid date:", time);
            return "Invalid date";
        }
        const formattedDate = dateObj.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
        return formattedDate;
    };

    const truncateDescription = (description, maxLength) => {
        if (description.length > maxLength) {
            return `${description.slice(0, maxLength)}...`;
        }
        return description;
    };

    const handleToggle = (feedId) => {
        setIsExpanded(prev => ({
            ...prev,
            [feedId]: !prev[feedId]
        }));
    };

    async function getDeleteAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("post/delete-post?post_id=" + id)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setDeleteModal(false)
            setDetailModal(false)
            getPostAPI()
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
        else {
            NotificationManager.error(result.message)
        }
    }

    return (
        <>
            {isLoading && <ReactLoader />}
            <div>
                <div className="main-inner-content">
                    <div className="row">
                        <div className="col-12">
                            <div className="center-content">
                                <div className="center-container py-4">
                                    <div className="social_feed_main">
                                        <div className="feed_input mb-4" >
                                            <div className="d-flex" onClick={() => setPostModal(true)} style={{ cursor: "pointer" }}>
                                                <div className="feed-user-top">
                                                    <img src={userDetail.profile_pic ? userDetail.profile_pic : profile_image_placeholder} className="userimg" alt="User" />
                                                </div>
                                                <div className="feed-img position-relative">
                                                    <input readOnly className="post-input" placeholder="What's on your mind?" />
                                                    <button className="post-btn">Post</button>
                                                    <div className="feed-upload" > <span className="upload-img"><img src={galleryImg} /></span> Image/Video</div>
                                                </div>
                                            </div>
                                        </div>
                                        {feeds.map(feed => 
                                        {
                                            const mediaIndex = mediaIndexMap[feed.id] || 0;

                                            return (  <div key={feed.id} className="feed_details mb-4">
                                                <div className="feed-user">
                                                    <a href="#" className="text-decoration-none text-black">
                                                        <img src={feed.user_profile_pic?feed.user_profile_pic:profile_image_placeholder} className="userimg" alt="User" />
                                                    </a>
                                                    <div>
                                                        <a className="text-decoration-none text-black">
                                                            <h4 className="fw-600 mb-2 lh-1">{feed.user_name}</h4>
                                                        </a>
                                                        <p className="fw-500 mb-0 side_text lh-1">{date(feed.created_at)}</p>
                                                    </div>
                                                    <div className='dotschedule bg-transparent'>
                                                        <div className='dropdownschedule'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="custom-dropdown-toggle">
                                                                    <div className="post-options">
                                                                        <img src={dotshorizontal} className="btn pl-2 pr-0 py-2" alt="Options" />
                                                                    </div>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='record-card-dropdown'>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setId(feed.id)
                                                                            setEditPost(true)
                                                                        }}
                                                                    >Edit</Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setId(feed.id)
                                                                            setDeleteModal(true)
                                                                        }}
                                                                    >Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feed_image position-relative" style={{ cursor: "pointer" }}>
                                                    {
                                                        feed?.post_details[mediaIndex]?.media_type == 1 ?
                                                            <img
                                                                onClick={() => {
                                                                    setId(feed.id);
                                                                    setDetailModal(true);
                                                                }}
                                                                src={feed?.post_details[mediaIndex]?.media_url}
                                                                className="feed_inner_images"
                                                                onLoad={(e) => {
                                                                    const isPortrait = e.target.naturalHeight > e.target.naturalWidth;
                                                                    e.target.className = isPortrait
                                                                      ? "feed_inner_images-portrait"
                                                                      : "feed_inner_images";
                                                                  }}
                                                                alt="Feed"
                                                            /> :
                                                            <video
                                                                onClick={() => {
                                                                    setId(feed.id)
                                                                    setDetailModal(true)
                                                                }}
                                                                src={feed?.post_details[mediaIndex]?.media_url}
                                                                className="feed_inner_images"
                                                                onLoad={(e) => {
                                                                    const isPortrait = e.target.naturalHeight > e.target.naturalWidth;
                                                                    e.target.className = isPortrait
                                                                      ? "feed_inner_images-portrait"
                                                                      : "feed_inner_images";
                                                                  }}
                                                                controls
                                                                autoPlay
                                                            ></video>
                                                    }
                                                    {
                                                        feed?.post_details?.length > 1 &&
                                                        <>
                                                            {
                                                                hasPrevious(feed.id)  ?
                                                                    <img src={pinkLeftIcons}
                                                                    onClick={() => handlePrevious(feed.id, feed.post_details.length)}
                                                                    className="img-fluid left-icons" alt="" />
                                                                    : <img src={Lefticons} className="img-fluid left-icons" alt="" style={{ cursor: "not-allowed" }} />
                                                            }

                                                            {
                                                                hasNext(feed.id, feed.post_details.length) ?
                                                                    <img src={pinkRightIcons}
                                                                    onClick={() => handleNext(feed.id, feed.post_details.length)}
                                                                    className="img-fluid right-icons" alt="" />
                                                                    :
                                                                    <img src={Righticons}
                                                                        style={{ cursor: "not-allowed" }}
                                                                        className="img-fluid right-icons" alt="" />
                                                            }</>
                                                    }

                                                </div>
                                                <div className="social_icon_feed" style={{ cursor: "pointer" }}>
                                                    <div className="like_feed d-flex align-items-center gap-2">
                                                        {
                                                            feed.is_liked == 1 ?
                                                                <img src={favRed} alt="like-icon" className="likes-icons" onClick={() => addFavourites(feed)} /> :
                                                                <img src={favouritesDark} alt="like-icon" className="likes-icons" onClick={() => addFavourites(feed)} />
                                                        }
                                                        <h5 className="total_like mb-0">{feed.total_likes}</h5>
                                                    </div>
                                                    <div onClick={() => {
                                                        setId(feed.id)
                                                        setDetailModal(true)
                                                    }} style={{ cursor: "pointer" }} className="like_feed d-flex align-items-center gap-2">
                                                        <img src={message} alt="Comment" />
                                                        <h5 className="total_like mb-0">{feed.total_comments}</h5>
                                                    </div>
                                                    <div onClick={() => {
                                                        setId(feed.id)
                                                        setShareModal(true)}} className="like_feed d-flex align-items-center gap-2">
                                                        <img src={Send_feed} className="share-icons" style={{ cursor: "pointer" }} alt="Share" />
                                                        <h5 className="total_like mb-0">{feed.share_count}</h5>
                                                    </div>
                                                </div>

                                                {/* <div className="totallikecomment px-4" onClick={() => setDetailModal(true)} style={{ cursor: "pointer" }}>
                                                    <div className="d-flex justify-content-start gap-2">
                                                        <span className="d-flex justify-content-start mt-2">
                                                            <img src={postlike} alt="like" style={{ width: "28px", height: "28px", marginRight: "-7px" }} />
                                                            <img src={heartlike} alt="love" style={{ width: "28px", height: "28px", marginRight: "-7px" }} />
                                                            <img src={happy} alt="angry" style={{ width: "28px", height: "28px" }} />
                                                        </span>
                                                        <p className="mt-1 mb-1"> Liked by <span className="fw-600"> John  </span> and <span className="fw-600"> 123 others</span></p>
                                                    </div>
                                                </div> */}
                                                {/* <div className="totallikecomment px-4" onClick={() => setDetailModal(true)} style={{ cursor: "pointer" }}>
                                                    <p> Liked by <span className="fw-600"> {feed.user} </span> and <span className="fw-600"> 123 others</span></p>
                                                </div> */}
                                                <div className="description_feed px-4" onClick={() => {
                                                    setId(feed.id)
                                                    setDetailModal(true)
                                                }} style={{ cursor: "pointer" }}>
                                                    <p className="mb-0 side-text">
                                                        {isExpanded[feed.id] ? feed.description : truncateDescription(feed.description, 270)}

                                                        {feed.description.length > 270 && (
                                                            <span onClick={(e) => {
                                                                 e.stopPropagation();
                                                                handleToggle(feed.id)
                                                            }} className="fw-600 blue-text">{" "}
                                                                {isExpanded[feed.id] ? "Less" : "...More"}
                                                            </span>

                                                        )}
                                                    </p>
                                                </div>
                                            </div>)
                                        }
                                          
                                        )}
                                    </div>
                                    {
                                        (hasMoreItems) &&
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={handleLoadMore}
                                            className="load-text mb-3 mx-3">
                                            Load More...
                                        </div>
                                    }
                                    <div ref={loadMoreRef}></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 mt-4 mt-lg-0">
                            <div className="right-aside following_tab">
                                <div className="following_heading">
                                    <h5 className="mb-0 fw-600">Following</h5>
                                </div>
                                <div>
                                    <ul className="following_listing ps-0">
                                        <li>
                                            <div className="following_main">
                                                <div className="following_profile">
                                                    <img src={dummy} alt="Profile" />
                                                </div>
                                                <div className="following_text">
                                                    <p className="mb-0 blue-text fw-500 f16-size">John Smith</p>
                                                </div>
                                             </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {shareModal && <ShareModalCreator setShareModal={setShareModal} id={id} getAPI={getPostAPI}/>}
            {postModal && <PostModal setIsLoading={setIsLoading} isLoading={isLoading} setPostModal={setPostModal} userDetail={userDetail} getPostAPI={getPostAPI} />}
            {detailModal && <DetailModal getPostAPI={getPostAPI} isLoading={isLoading} setDetailModal={setDetailModal} userDetail={userDetail} id={id} setIsLoading={setIsLoading} getDeleteAPI={getDeleteAPI}  />}
            {deleteModal && <DeletePost setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />}
            {editPost && <PostModal setIsLoading={setIsLoading} isLoading={isLoading} setPostModal={setEditPost} userDetail={userDetail} id={id} getPostAPI={getPostAPI} />}
        </>
    );
};

export default SocialFeeds;
