import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { buy_btn, favGrey, favRed, noSeries, notification, playbg, playBtn } from '../../Constants/images'
import ROUTES from '../../Helper/route-helper'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import BuyModal from '../../Components/PaymentModal/BuyModal'

function SeriesChannel({ loading, detailList, getListAPI, setLoading }) {


    const navigate = useNavigate()
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const handleNavigate = (id) => {
        navigate(ROUTES.SERIES_DETAIL, { state: { id: id } })
    }

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div>
                    <div className='row mt-4'>
                        {detailList?.length == 0 && loading === false ? (
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={noSeries} alt="no series" />
                            </div>
                        ) : (
                            detailList.length > 0 && detailList.map(item => (
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item.id}>
                                    <div className="latest-video mx-auto">
                                        <div className='position-relative'>
                                            <div className="latest-video-content pt-2 pt-sm-3 px-2 px-sm-3">
                                                <img src={item.thumbnail} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                {(item.is_purchase === 0 && item.price != 0 && item.tier_details == null) && <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${item.price}`} secondValue={"00"} />}

                                            </div>
                                        </div>
                                        <div className="latest-video-cus px-3 py-3">
                                            <div className="tooltip-container">
                                                <h4 className="fw-600 mb-0">{item.title?.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</h4>
                                                <span className="tooltip-text">{item.title}</span>
                                            </div>
                                            <div className="latest-series-name">
                                                <div className="video-tile">
                                                    <p className='mb-0 fw-400'>{item.episodesCount} {item.episodesCount === 1 ? "Episode" : "Episodes"}</p>
                                                    <p className='mb-0 fw-500'>{item.year}</p>
                                                </div>
                                                {
                                                    item.tier_details == null ?
                                                        <>
                                                            {(item.is_purchase === 0 && item.price != 0) ? (
                                                                <span style={{ cursor: 'pointer' }} className='text-end'
                                                                    onClick={() => handleOpen(item)}
                                                                >
                                                                    <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                </span>
                                                            ) : (
                                                                <span style={{ cursor: "pointer" }}
                                                                    onClick={() => handleNavigate(item.id)}
                                                                    className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                            )}</> :

                                                        <span style={{ cursor: 'pointer' }} className='text-end'
                                                            onClick={() => {
                                                                if (item.tier_details?.is_purchased == 1) {
                                                                    handleNavigate(item.id)
                                                                }
                                                                else {
                                                                    localStorage.setItem('tierContentId', item.id + 'series')
                                                                    navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: item.tier_details.tier_id } })
                                                                }
                                                            }

                                                            }
                                                        >
                                                            <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                        </span>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                 
                    {buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} itemType="1" getApi={getListAPI} />}

                </div>
            </div >
        </>
    )
}

export default SeriesChannel