import React from 'react'
import { modalCross } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { NotificationManager } from 'react-notifications';
import PaymentForm from '../../Components/PaymentModal/payement';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const BuyPlanModal = ({ setBuyModal, getApi, setLoading, item, channelId,
}) => {

    async function buyApi(transactionId, status) {
        let data;
        data = {
            "tier_id": item.id,
            "channel_id": channelId,
            "mode_of_payment": "credit_card",
            "transaction_id": transactionId,
            "payment_status": status == "succeeded" ? 1 : 0
        }
        setLoading(true)
        let result = await apiHelper.postService('tier/activate-tier', data)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false)
            setBuyModal(false)
            getApi()
            NotificationManager.success(result.message)
        }
        else {
            setLoading(false)
            // NotificationManager.error(result.message)
        }
    }

    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Payment Details</h3>
                        <a onClick={() => {
                            setBuyModal(false)
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                        <Elements stripe={stripePromise}>

                            <PaymentForm price={item?.price} setLoading={setLoading} buyApi={buyApi} />
                        </Elements>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default BuyPlanModal
