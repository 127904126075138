import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { modalCross, dummy, dotshorizontal, heartfill, message, Send_feed, smile, dummyFeed, Whitecross, favRed, favouritesDark, profile_image_placeholder, Pinksmile, pinkRightIcons, Righticons, pinkLeftIcons, Lefticons } from "../../Constants/images";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import io from "socket.io-client";
import { NotificationManager } from "react-notifications";
import EmojiPicker from 'emoji-picker-react';
import ShareModal from "./shareModal";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
const SOCKETURL = process.env.REACT_APP_SOCKET_BASE_URL

const UserDetailModal = ({ setDetailModal, id, setIsLoading, isLoading, getPostAPI }) => {
  const location = useLocation()
  const [postData, setPostData] = useState("")
  const [commentList, setCommentList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);
  const [text, setText] = useState('');
  const [_, forceUpdate] = useState(0)
  const [reply, setReply] = useState(0)
  const [replyId, setReplyId] = useState(null)
  const socket = io(SOCKETURL);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const firstCommentRef = useRef(null);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [page, setPage] = useState(1);
  const loadMoreRef = useRef(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [color, setColor] = useState('#000000');
  const[focus,setFocus] = useState(false)
  const navigate = useNavigate()

  const [styles, setStyles] = useState({
    isBold: false,
    isItalic: false,
    isUnderline: false,
    fontColor: '#000000'
  });

  const handleAddComment = async () => {
    if (firstCommentRef.current) {
      firstCommentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNext = () => {
    if (postData.post_details.length > 0) {
      setMediaIndex(prevIndex => (prevIndex + 1) % postData.post_details.length);
    }
  };

  const handlePrevious = () => {
    if (postData.post_details.length > 0) {
      setMediaIndex(prevIndex => (prevIndex - 1 + postData.post_details.length) % postData.post_details.length);
    }
  };

  const hasPrevious = mediaIndex > 0;
  const hasNext = mediaIndex < postData?.post_details?.length - 1;

  useEffect(() => {
    if (socket) {
      socket.on(`commentList${id}`, (commentData) => {
        console.log(commentData, "commentData")
        const commentsArray = Array.isArray(commentData) ? commentData : [commentData];
        updateCommentList(commentsArray);
      });
      return () => {
        socket.off(`commentList${id}`);
      };
    }
  }, [socket, id]);

  const updateCommentList = (newComments) => {
    setCommentList(prevCommentList => {
      const commentMap = new Map(prevCommentList.map(comment => [comment.id, comment]));
      newComments.forEach(comment => {
        commentMap.set(comment.id, comment);
      });

      return Array.from(commentMap.values());
    });
  };


  const handleTextChange = (value) => {
    setText(value);
    console.log(value, "valueeeee")
    localStorage.setItem("value", value)
  };

  const quillRef = useRef(null);

  const applyFormatting = (command, colorApplied) => {
    const quill = quillRef?.current?.editor
    if (quill) {
      switch (command) {
        case 'bold':
          const isBold = !quill.getFormat().bold;
          quill.format('bold', isBold);
          setStyles((prev) => ({ ...prev, isBold }));
          break;
        case 'italic':
          const isItalic = !quill.getFormat().italic;
          quill.format('italic', isItalic);
          setStyles((prev) => ({ ...prev, isItalic }));
          break;
        case 'underline':
          const isUnderline = !quill.getFormat().underline;
          quill.format('underline', isUnderline);
          setStyles((prev) => ({ ...prev, isUnderline }));
          break;
        case 'color':
          quill.format('color', colorApplied);
          setStyles((prev) => ({ ...prev, fontColor: colorApplied }));
          break;
        default:
          break;
      }
    }
  };


  const toggleStyle = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  async function addFavourites(item) {
    if (item.is_liked == 0) {
      item.is_liked = 1
      item.total_likes = item.total_likes + 1;

    } else {
      item.is_liked = 0
      item.total_likes = item.total_likes - 1;

    }
    forceUpdate(Math.random())
    let result = await apiHelper.getRequest("post/like-dislike-posts?post_id=" + item.id)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  async function addFavouritesComment(item) {
    const data = JSON.stringify({
      "post_id": id,
      "comment_id": item.id
    })
    if (item.is_liked == 0) {
      item.is_liked = 1
      item.total_likes = item.total_likes + 1;

    } else {
      item.is_liked = 0
      item.total_likes = item.total_likes - 1;
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest(`post/like-dislike-comment?comment_id=${item.id}`, data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  const sendMessage = async (e) => {
    e.preventDefault();
    if (reply == 0) {
      addComment()
    }
    else if (reply == 1) {
      addReply()
    }
  }
  const formatText = (text) => {
    let formattedText = text;
    if (styles.isBold) {
      formattedText = `<b>${formattedText}</b>`;
    }
    if (styles.isItalic) {
      formattedText = `<i>${formattedText}</i>`;
    }
    if (styles.isUnderline) {
      formattedText = `<u>${formattedText}</u>`;
    }
    if (styles.fontColor) {
      formattedText = `<span style="color: ${styles.fontColor};">${formattedText}</span>`;
    }
    return formattedText;
  };

  const addComment = async () => {
    const formattedText = formatText(text);
    // setIsLoading(true)
    setText('');
    const data = JSON.stringify({
      "post_id": id,
      "comment": formattedText
    });

    let result = await apiHelper.postRequest("post/add-post-comment", data);
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      setReply(0);
      handleAddComment();
      getCommentList("text");
      localStorage.setItem("value", " ")
      setShowEmojiPicker(false);
      setColorPickerVisible(false)
      getDetailAPI("text")
    }
  };


  const handleReply = (id) => {
    setFocus(true)
    if (reply == 1) {
      setReply(0);
    }
    else {
      setReply(1)
    }
    setReplyId(id);
  };

  async function addReply() {
    setIsLoading(true)
    setText('');
    const formattedText = formatText(text);
    const data = JSON.stringify({
      "post_id": id,
      "comment_id": replyId,
      "comment": formattedText
    })
    let result = await apiHelper.postRequest("post/add-comment-reply", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
      getCommentList("text")
      setReply(0);
      localStorage.setItem("value", " ")
      setShowEmojiPicker(false);
      setColorPickerVisible(false)
      getDetailAPI("text")
    }
  }

  const handleColorChange = (color) => {
    setColor(color.hex);
    applyFormatting('color', color.hex);
  };



  async function getDetailAPI(text) {
    if (text != "text") {
      setIsLoading(true)
    }
    let result = await apiHelper.getRequest(`post/post-details?post_id=${id}`)
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setIsLoading(false)
      setPostData(result.data)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function getCommentList(text) {
    if (text != "text") {
      setIsLoading(true)
    }
    let result = await apiHelper.getRequest(`post/post-comments-list?post_id=${id}&offset=${page}&limit=10`)
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setIsLoading(false)
      setIsLoading(false)
      const newBites = result?.data.data;
      if (page === 1) {
        setCommentList(result?.data.data);
      } else {
        setCommentList((prevBites) => [...prevBites, ...newBites]);
      }
      setHasMoreItems(newBites.length === 10);
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailAPI()
  }, [refresh])

  useEffect(() => {
    getCommentList()
  }, [refresh, page])

  const date = (time) => {
    const dateObj = new Date(time);
    if (isNaN(dateObj)) {
      console.error("Invalid date:", time);
      return "Invalid date";
    }

    const formattedDate = dateObj.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    return formattedDate;
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return `${description.slice(0, maxLength)}...`;
    }
    return description;
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  async function getDeleteCommentAPI(item) {
    setIsLoading(true)
    let result = await apiHelper.getRequest(`post/delete-post-comment?post_id=${id}&comment_id=${item.id}`)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      getCommentList()
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
    else {
      NotificationManager.error(result.message)
    }
  }

  function getTimeAgo(createdAt) {
    const currentDate = new Date();
    const createdAtDate = new Date(createdAt);
    const timeDifference = currentDate.getTime() - createdAtDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return days + (days === 0 || days === 1 ? "day ago" : " days ago");
    } else if (hours > 0) {
      return hours + (hours === 0 || hours === 1 ? " hour ago" : " hours ago");
    } else if (minutes > 0) {
      return minutes + (minutes === 0 || minutes === 1 ? " min ago" : " mins ago");
    } else {
      return (seconds >= 0 ? seconds : 0) + "sec ago";
    }
  }

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };


  const handleEmojiSelect = (emoji) => {
    let valueMessage = localStorage.getItem("value")
    if (valueMessage == null) {
      valueMessage = " "
    }
    setText(valueMessage + emoji.emoji)
    localStorage.setItem("value", valueMessage + emoji.emoji)
  }

  
  return (
    <>
      {
        postData != "" &&
        <div
          className="modal fade show modal-cast"
          style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}
        >
          <div className="loader"></div>
          <div className="modal-dialog modal-xl modal-dialog-centered social-modals">
            <div className="modal-content border-0">
              <div className="modal-body verify-modal-box py-0 ps-0 pe-0 pe-lg-3">
                <div className="row bg-white">
                  <div className="col-lg-8 position-relative px-0">
                    {
                      postData.post_details[mediaIndex].media_type == 1 ? (
                        <img
                          src={postData?.post_details[mediaIndex].media_url}
                          className="detail-modal-img"
                          alt="Feed"
                          onLoad={(e) => {
                            const isPortrait = e.target.naturalHeight > e.target.naturalWidth;
                            e.target.className = isPortrait
                              ? "detail-modal-img-portrait"
                              : "detail-modal-img";
                          }}
                        />
                      ) : (
                        <video
                          src={postData?.post_details[mediaIndex].media_url}
                          className="detail-modal-img"
                          controls
                          autoPlay
                          onLoadedMetadata={(e) => {
                            const isPortrait = e.target.videoHeight > e.target.videoWidth;
                            e.target.className = isPortrait
                              ? "detail-modal-img-portrait"
                              : "detail-modal-img";
                          }}
                        ></video>
                      )
                    }

                    <a className="m-0 px-0 btn-closed-main user-cross-btns" data-bs-dismiss="modal" aria-label="Close" >
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDetailModal(false)
                          const currentPath = window.location.pathname;
                          const newPath = currentPath.split('/').slice(0, -1).join('/');
                          window.history.replaceState({}, '', newPath);
                          getPostAPI()
                        }}
                        src={Whitecross}
                        alt=""
                        className="img-fluid btn-cloesed btn-closed-cast"
                      />
                    </a>
                    {
                      postData?.post_details.length > 1 &&
                      <>
                        {
                          hasPrevious ?
                            <img src={pinkLeftIcons}
                              onClick={handlePrevious}
                              style={{ cursor: "pointer" }}

                              className="img-fluid left-modal-icons" alt="" />
                            : <img src={Lefticons} className="img-fluid left-modal-icons" alt="" style={{ cursor: "not-allowed" }} />
                        }
                        {
                          hasNext ?
                            <img src={pinkRightIcons}
                              onClick={handleNext}
                              style={{ cursor: "pointer" }}

                              className="img-fluid right-modal-icons" alt="" />
                            :
                            <img src={Righticons}
                              style={{ cursor: "not-allowed" }}
                              className="img-fluid right-modal-icons" alt="" />
                        }
                      </>}
                  </div>
                  <div className="col-lg-4 p-lg-0">
                    <div className="feed-user-modal px-2">
                      <a href="" className=" text-decoration-none text-black">
                        <img src={postData.user_profile_pic} className="userimgmodal" />
                      </a>
                      <div className="">
                        <a href="" className=" text-decoration-none text-black">
                          <h5 className="fw-600 mb-2 lh-1">{postData.user_name}</h5>
                        </a>
                        <p className="fw-500  mb-0 side_text lh-1">{date(postData.created_at)}</p>
                      </div>

                    </div>
                    <div className="description_feed px-2" onClick={handleToggle}>
                      <p className="mb-3 side-text modal-side-text create-modal-post">
                        {isExpanded ? postData.description : truncateDescription(postData.description, 140)}
                        {postData.description.length > 140 && (
                          <span className="fw-600 blue-text" style={{ cursor: 'pointer' }}>{' '}
                            {isExpanded ? "Less" : " ...More"}
                          </span>
                        )}
                      </p>
                    </div>

                    {/* <div className="totallikecomment px-2">

                      <p className="">

                        Liked by <span className="fw-600"> John Smith </span> and{" "}
                        <span className="fw-600"> 123 </span>others
                      </p>
                    </div> */}
                    <div className="social_icon_feed modal_social_icon_feed" style={{ cursor: "pointer" }}>
                      <div className="like_feed d-flex align-items-center gap-2">
                        {
                          postData.is_liked == 1 ?
                            <img src={favRed} alt="like-icon" className="likes-icons" onClick={() => addFavourites(postData)} /> :
                            <img src={favouritesDark} alt="like-icon" className="likes-icons" onClick={() => addFavourites(postData)} />
                        }
                        <h5 className="total_like mb-0">{postData.total_likes}</h5>
                      </div>
                      <div className="like_feed d-flex align-items-center gap-2">
                        <img src={message} />
                        <h5 className="total_like mb-0">{postData.total_comments}</h5>
                      </div>
                      <div className="like_feed d-flex align-items-center gap-2" style={{ cursor: 'pointer' }} onClick={() => setShareModal(true)}>
                        <img src={Send_feed} className="share-icons" />
                        <h5 className="total_like mb-0">{postData.share_count}</h5>
                      </div>
                    </div>
                    <div>
                      <h5 className="px-2 py-3" >Comments</h5>
                      <div className="post-comments custom-post-comments">
                        {
                          commentList.length > 0 && commentList?.map((item, index) => (
                            <div key={item.id} ref={index === 0 ? firstCommentRef : null}>
                              <div className="comment-section px-2 ">
                                <div className="d-flex">
                                  <div>
                                    <img src={item.user_profile_pic ? item.user_profile_pic : profile_image_placeholder} className="commentImg-modal" />
                                  </div>
                                  <div className="comment-text  mx-2 p-2">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <h6 className="fw-600 mb-2 lh-1">{item.user_name}</h6>
                                        <p className="fw-200  mb-0 side_text lh-1" style={{ fontSize: "12px" }}>{getTimeAgo(item.created_at)}</p>
                                      </div>
                                      <div style={{ lineHeight: '10px' }}>
                                        <div className='dotschedule bg-transparent '>
                                          <div className='dropdownschedule'>
                                            <Dropdown>
                                              <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <div className="post-options">
                                                  <img src={dotshorizontal} className="btn pl-2 pr-0 py-2" alt="Options" />
                                                </div>
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className='record-card-dropdown edits-drops'>
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    getDeleteCommentAPI(item)
                                                  }}
                                                >Delete</Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      <p className="mb-0 side-text modal-side-text" dangerouslySetInnerHTML={{ __html: item.comment }}></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="px-5 d-flex" style={{ cursor: "pointer" }}>
                                  <div className="py-1">
                                    {/* <img src={heartfill} style={{ height: 16, width: 40 }} /> */}
                                    {
                                      item.is_liked == 1 ?
                                        <img src={favRed} alt="like-icon" onClick={() => addFavouritesComment(item)} /> :
                                        <img src={favouritesDark} alt="like-icon" onClick={() => addFavouritesComment(item)} />
                                    }

                                  </div>
                                  <p style={{ fontSize: "12px" }} className="px-2 text-black fw-300">{item.total_likes} Like</p>
                                  <p style={{ fontSize: "12px", cursor: "pointer" }} onClick={() => handleReply(item.id)} className="px-2">{item.total_reply_count} Reply</p>

                                </div>
                              </div>
                              {
                                item.comment_reply.length > 0 && item.comment_reply.map((item) => (
                                  <div className="reply-section px-5 " key={item.id}>
                                    <div className="d-flex">
                                      <div>
                                        <img src={item.user_profile_pic ? item.user_profile_pic : profile_image_placeholder} className="commentImg-modal" />
                                      </div>
                                      <div className="comment-text  mx-2 p-2">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <h6 className="fw-600 mb-2 lh-1">{item.user_name}</h6>
                                            <p className="fw-200  mb-0 side_text lh-1" style={{ fontSize: "12px" }}>{getTimeAgo(item.created_at)}</p>
                                          </div>
                                          <div style={{ lineHeight: '10px' }}>
                                            <div className='dotschedule bg-transparent '>
                                              <div className='dropdownschedule'>
                                                <Dropdown>
                                                  <Dropdown.Toggle id="custom-dropdown-toggle">
                                                    <div className="post-options">
                                                      <img src={dotshorizontal} className="btn pl-2 pr-0 py-2" alt="Options" />
                                                    </div>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu className='record-card-dropdown edits-drops'>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        getDeleteCommentAPI(item)
                                                      }}
                                                    >Delete</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <p className="mb-0 side-text modal-side-text" dangerouslySetInnerHTML={{ __html: item.comment }}></p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="px-5 d-flex">
                                      <div className="py-1">
                                        {/* <img src={heartfill} style={{ height: 16, width: 40 }} /> */}
                                        {
                                          item.is_liked == 1 ?
                                            <img src={favRed} alt="like-icon" onClick={() => addFavouritesComment(item)} /> :
                                            <img src={favouritesDark} alt="like-icon" onClick={() => addFavouritesComment(item)} />
                                        }
                                      </div>
                                      <p style={{ fontSize: "12px" }} className="px-2 text-black fw-300">{item.total_likes} Like</p>
                                      {/* <p style={{ fontSize: "12px" }} className="px-2">{item.total_reply_count} Reply</p> */}

                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          ))
                        }
                        {
                          (hasMoreItems && commentList.length > 0) &&
                          <div style={{ cursor: 'pointer', fontSize: "15px" }}
                            onClick={handleLoadMore}
                            className="load-text mb-3 mx-3">
                            Load More...
                          </div>
                        }
                        <div ref={loadMoreRef} ></div>
                      </div>
                      <form >
                        <div className={`comment-post-inputmodal my-1 ${focus && 'focus-editor'}`}>
                          <div className="d-flex position-relative">
                            <img src={Pinksmile} onClick={toggleEmojiPicker} className="smile-img my-2" alt="" style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                            />
                            {showEmojiPicker && (
                              <EmojiPicker
                                onEmojiClick={handleEmojiSelect}
                                disableAutoFocus={true}
                                className='custome-emoji'
                                pickerStyle={{
                                  position: 'absolute',
                                  bottom: '50px',
                                }}
                              />
                            )}
                             <ReactQuill
                              key={reply}
                              className="comment-input-feed"
                              placeholder={reply === 0 ? "Type a Comment" : "Type a Reply"}
                              value={text}
                              onChange={(content) => {
                                setFocus(false)
                                const plainText = new DOMParser().parseFromString(content, "text/html").body.textContent || "";
                                setText(plainText.trim());
                              }}
                              modules={{ toolbar: false }}
                              ref={quillRef}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                  e.preventDefault();
                                  const currentValue = text?.trim();
                                  if (currentValue) {
                                    sendMessage(e);
                                  }
                                }
                                if (e.key === "Enter" && e.shiftKey) {
                                  return;
                                }
                                if (e.key === "Backspace") {
                                  const valueMessage = localStorage.getItem("value");
                                  if (valueMessage?.length > 0) {
                                    setText(valueMessage.slice(0, -1));
                                  }
                                }
                                if (e.key === " " && text.trim() === "") {
                                  e.preventDefault();
                                }
                              }}
                            />


                          </div>
                          <hr className="mx-3 my-0" style={{ color: "#B6B6B6" }} />
                          <div className="position-relative px-2 py-3">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div className="m-0 px-2" style={{ fontWeight: 700, color: `${styles.isBold ? "#ca3392" : "#B6B6B6"}`, cursor: "pointer", fontSize: '20px' }} onClick={() => applyFormatting('bold')} >B </div>
                                <div className="m-0 px-2" style={{ fontStyle: "italic", color: `${styles.isItalic ? "#ca3392" : "#B6B6B6"}`, cursor: "pointer", fontSize: '20px' }} onClick={() => applyFormatting('italic')}>I</div>
                                <div className="m-0 px-2 " style={{ textDecoration: "underline", color: `${styles.isUnderline ? "#ca3392" : "#B6B6B6"}`, cursor: "pointer", fontSize: '20px' }} onClick={() => applyFormatting('underline')}>U</div>
                                <div className="m-0 px-2" style={{ color: `${styles.fontColor != '#000000' ? "#ca3392" : "#B6B6B6"}`, cursor: "pointer", textDecoration: "underline", fontSize: '20px' }} onClick={() => toggleStyle()}>A</div>
                              </div>
                              <button className="comment-post-btn" type="button" onClick={sendMessage}>post</button>
                            </div>
                          </div>
                        </div>
                      </form>
                      {colorPickerVisible && (
                        <div className="color-picker">
                          <SketchPicker
                            color={color}
                            onChangeComplete={handleColorChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {shareModal && <ShareModal setShareModal={setShareModal} id={id} getAPI={getDetailAPI} />}

              </div>
            </div>
          </div>
        </div>
      }

    </>

  );
};

export default UserDetailModal;
