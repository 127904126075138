import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { buy_btn, DEMO_IMAGE_FOUR, playBtn } from '../../Constants/images'
import ROUTES from '../../Helper/route-helper'

function GiftSeries({ setTabSwitch, loading, detailList }) {

    const navigate = useNavigate()

    const handleNavigate = (id) => {
        if(localStorage.getItem("role") == 2){
            localStorage.setItem("series_id", id)
            navigate(ROUTES.CREATER_SERIES_DETAIL)  
        }else{
            navigate(`/series-list/series-detail/${id}`)
        }
                
    }



    return (
        <>
            <div className="container-fluid">
                <div>
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length == 0 && loading === false ? (
                            <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={DEMO_IMAGE_FOUR} alt="No Challenges" />
                                <h6 className='position-absolute' style={{ bottom: 15 }}>No Series yet</h6>
                            </div>
                        ) : (
                            <>
                                {
                                    detailList.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content pt-2 pt-sm-3 px-2 px-sm-3">
                                                        <img src={item.thumbnail} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />

                                                    </div>
                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                    <div class="grid-data-detail-lives position-relative w-100">
                                                        <div className="tooltip-container">
                                                            <h4 className="fw-600 mb-0">{item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <div className="latest-series-name">
                                                            <div className="video-tile">
                                                                <p className='mb-0 fw-400'>{item.episodesCount} {item.episodesCount === 1 ? "Episode" : "Episodes"}</p>
                                                                <p className='mb-0 fw-500'>{item.year}</p>
                                                            </div>
                                                            
                                                                <span style={{ cursor: "pointer" }} onClick={() => handleNavigate(item.id)} className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )}
                    </div>


                </div>
            </div >
        </>
    )
}

export default GiftSeries