import React, { useState, useEffect } from 'react'
import { audio_img, crossthemebgpink, modalCross, selectAudio } from '../../Constants/images'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ERR_MESSAGE from '../../Helper/error-helper';
import uploadToS3 from '../component/fileUpload';
import ReactLoader from '../../ControlComponents/react-loader';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import { NotificationManager } from 'react-notifications';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"

const AddAudioModal = ({ setAddAudioModal, setTabSwitch, deltId, getAudiosAPI, setAddDetailAudioModal }) => {
    const [progressLoading, setProgressLoading] = useState(false)
    const [progress, setProgress] = useState();
    const [thumbnailFileUrl, setThumbnailFileUrl] = useState([]);
    const [errors, setErrors] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [price, setPrice] = useState("");
    const [refresh, setRefresh] = useState(false)

    const [info, setInfo] = useState({
        title: "",
        description: "",
    })

    function changeHandler(e) {
        setErrors("")
        const newValue = e.target.value?.slice(0, 3);
        setPrice(newValue);
    }

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setInfo({
            ...info,
            [name]: value
        })
    }

    async function getDetailAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`shop/get-shop-media?shopType=audio&shop_id=${deltId}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
              setInfo(result.data.filter(item => item.id == deltId)[0])
            setPrice(result.data.filter(item => item.id == deltId)[0].price)
            const filterArray = result?.data?.filter(item => item.id == deltId)[0]?.shop_media?.map((item) => {
                return { media_url: item?.media_url };
            });
            setThumbnailFileUrl(filterArray)

        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        if (deltId != undefined) {
            getDetailAPI()
        }
    }, [refresh])

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { title, description } = info;
        if (thumbnailFileUrl?.length === 0) {
            formIsValid = false;
            errors["thumbnailFileUrl"] = ERR_MESSAGE.ADD_AUDIO
        }
        if (!title || title.trim().length === 0) {
            formIsValid = false;
            errors["title"] = ERR_MESSAGE.TITLE_EMPTY
        }
        if (!description || description.trim().length === 0) {
            formIsValid = false;
            errors["description"] = ERR_MESSAGE.SUMMARY_EMPTY
        }

        if (!price) {
            formIsValid = false;
            errors["price"] = ERR_MESSAGE.EMPTY_PRICE
        }

        setErrors(errors);
        return formIsValid;
    }

    const fileChange = (e) => {
        setErrors("")
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('audio/')) {
                const formData = new FormData();
                formData.append("audio", file);
                fileUpload(file)
            }
        }
    };

    const fileUpload = async (file, thumb) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        setProgressLoading(true)
        try {
            if (file) {
                await uploadToS3(thumb, file, state, setProgress);
                if (state.videoLocation) {
                    setProgressLoading(false)
                    setThumbnailFileUrl(prevState => [
                        ...prevState,
                        {
                            media_url: state.videoLocation,
                        }
                    ]);
                }
            }

        } catch (error) {
        }
    };

    const handleRemoveAudio = (index) => {
        setThumbnailFileUrl((prevData) => {
            const updatedClipData = [...prevData];
            updatedClipData.splice(index, 1);
            return updatedClipData;
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsLoading(true)
            let data = {
                "title": info.title,
                "shop_type": "audio",
                "description": info.description,
                "price": parseInt(typeof price === 'string' ? price.slice(0, 3) : String(price).slice(0, 3)),
                "media": thumbnailFileUrl
            }
            try {
                const result = await apiHelper.postRequest("shop/add-shop-media", data);
                if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                    setIsLoading(false)
                    if (result.message == "shop add successfully") {
                        NotificationManager.success("Audio added successfully")
                    }
                    else {
                        NotificationManager.success(result.message)
                    }
                    getAudiosAPI()
                    setAddAudioModal(false);
                    setTabSwitch(4)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsLoading(true)
            let data = {
                "shop_id": deltId,
                "title": info.title,
                "shop_type": "audio",
                "description": info.description,
                "price": parseInt(typeof price === 'string' ? price.slice(0, 3) : String(price).slice(0, 3)),
                "media": thumbnailFileUrl
            }
            try {
                const result = await apiHelper.postRequest("shop/edit-shop-media", data);
                if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                    setIsLoading(false)
                    if (result.message == "Shop media updated successfully") {
                        NotificationManager.success("Audio updated successfully")
                    }
                    else {
                        NotificationManager.success(result.message)
                    }
                    getAudiosAPI()
                    setAddAudioModal(false);
                    setAddDetailAudioModal(false)
                    setTabSwitch(4)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }
    }

    return (
        <>
            {isLoading && <ReactLoader />}
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>
            }
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-lg  modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">{deltId != undefined ? "Edit" : "Add"} Audios</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setAddAudioModal(false)
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <form className='price-form-input'>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Title</label>
                                    <input type='text' className='border-edit  w-100' name="title"
                                        value={info.title} onChange={handleChange}
                                        placeholder='Enter Here' ></input>
                                    {errors.title && <span className="err_msg">{errors.title}</span>}
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Description</label>
                                    <textarea className=" text-summary place-grey d-block w-100"
                                        value={info.description} onChange={handleChange}
                                        placeholder="Enter Here" rows="6" name="description"></textarea>
                                    {errors.description && <span className="err_msg">{errors.description}</span>}
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Price</label>
                                    <TextField
                                        className='price-input'
                                        type='number'
                                        min="1"
                                        value={price}
                                        onChange={changeHandler}
                                        placeholder='Enter Price'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                    {errors.price && <span className="err_msg">{errors.price}</span>}
                                </div>
                                <div className='mb-3 mt-3'>
                                    <label className='fw-500 f18-size text-black mx-2'>Audios</label>
                                    <div className="d-flex my-1">
                                        <div className="m-2 position-relative">
                                            <span className="py-5 px-4 add-audio-head" >
                                                <img src={selectAudio} className="create-post-select-img" />
                                            </span>
                                            <input type='file' accept=".mp3, .wav,.m4a, .flac,.aac" style={{ cursor: "pointer" }} className='select-video-input' onChange={fileChange} />
                                        </div>
                                        <div className="d-flex justify-content-between overflow-scroll">
                                            {
                                                thumbnailFileUrl?.length > 0 && thumbnailFileUrl?.map((item, index) => (
                                                    <div className="m-2 position-relative cus-add-audio ">
                                                        <img src={audio_img} className="create-audio-img" />
                                                        <div className='audio-name'>
                                                            <span className='text-center'>{item?.media_url?.split('/')?.pop()}</span>
                                                        </div>
                                                        <img src={crossthemebgpink} className="create-post-cross-btn audio-cross-btn"
                                                            onClick={() => handleRemoveAudio(index)}
                                                            style={{ cursor: "pointer" }} />
                                                    </div>
                                                ))
                                            }

                                        </div>

                                    </div>
                                    {errors.thumbnailFileUrl && <span className="err_msg">{errors.thumbnailFileUrl}</span>}

                                </div>
                                {
                                    deltId != undefined ? <button type='submit' className='price-submit-btn' onClick={handleEdit}>Save</button> : <button type='submit' className='price-submit-btn' onClick={handleSubmit}>Save</button>
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAudioModal
