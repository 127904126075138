import React, { useEffect, useRef, useState } from 'react'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { NotificationManager } from 'react-notifications'
import PlanIncome from './planIncome'
import ShopIncome from './shopIncome'
import TipIncome from './tipIncome'
function Income() {
    const [tiplist, setTipList] = useState([])
    const [tabSwitch, setTabSwitch] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [offset, setOffset] = useState(1);
    const [scroll, setScroll] = useState(false)
    const loadMoreRef = useRef(null);
    const [totalIncome, setTotalIncome] = useState(null)
    let message = localStorage.getItem("message")

    useEffect(() => {
        if (message) {
            NotificationManager.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        }
    }, [])

    const handleLoadMore = () => {
        setScroll(true)
        setOffset(prevPage => prevPage + 1);
    };

    async function getApi() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/income-logs?item_type=${tabSwitch == 0 ? 2 : tabSwitch == 1 ? 1 : 3}&limit=${6}&offset=${offset}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            const newList = result?.data.data ? result?.data.data : result?.data;
            if (offset === 1) {
                setTipList(result?.data?.data ? result?.data?.data : result?.data)
            } else {
                setTipList((prevList) => [...prevList, ...newList]);
            }
            setHasMoreItems(newList?.length == 6);
            setTotalIncome(result.data.totalIncome)
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        if (loadMoreRef.current && scroll) {
            loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setScroll(false)
        }
    }, [tiplist, scroll]);


    useEffect(() => {
        getApi()
    }, [refresh, offset, tabSwitch])

    const getDate = (createdAt) => {
        const date = new Date(createdAt);
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
        return formattedDate
    }

    return (
        <div className="main-inner-content">
            {
                isLoading && <ReactLoader />
            }
            <section className="streaming mt-3 mb-4 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Total Income</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid">
                <div className="stream-btn pt-3 pb-4">
                    <button className={`common-btn ${tabSwitch == 0 ? "btn-blue" : "btn-white"} left-corner`} onClick={() => setTabSwitch(0)}>Plan</button>
                    <button className={`common-btn ${tabSwitch == 1 ? "btn-blue" : "btn-white"} left-corner`} onClick={() => setTabSwitch(1)}> Shop</button>
                    <button className={`common-btn ${tabSwitch == 2 ? "btn-blue" : "btn-white"} left-corner`} onClick={() => setTabSwitch(2)}>Tip</button>

                </div>
                <div className="row pb-4">
                    {
                        tabSwitch == 0 ?
                            <PlanIncome tiplist={tiplist} hasMoreItems={hasMoreItems} handleLoadMore={handleLoadMore} isLoading={isLoading} getDate={getDate} loadMoreRef={loadMoreRef} totalIncome={totalIncome} /> :
                            tabSwitch == 1 ? <ShopIncome tiplist={tiplist} hasMoreItems={hasMoreItems} handleLoadMore={handleLoadMore} isLoading={isLoading} getDate={getDate} loadMoreRef={loadMoreRef} totalIncome={totalIncome} /> :
                                <TipIncome tiplist={tiplist} hasMoreItems={hasMoreItems} handleLoadMore={handleLoadMore} isLoading={isLoading} getDate={getDate} loadMoreRef={loadMoreRef} totalIncome={totalIncome} />

                    }

                </div>
            </section>
        </div>
    )
}

export default Income