import React from 'react'
import { no_tip, profile_image_placeholder } from '../../Constants/images'

function ShopIncome({ tiplist, hasMoreItems, handleLoadMore, isLoading, getDate, loadMoreRef ,totalIncome}) {
    return (
        <>
            {
                tiplist?.length > 0 ? <>
                    <div className="col-12  pb-4 pb-lg-0">
                        <div className="subscribers-table">
                            <div className='d-flex justify-content-between'>
                                <h3 className='fw-600 mt-2 mb-4 mx-2'> Shop Income</h3>
                                <div className="total-tip  mt-2 mb-4 mx-2"><p className="mb-0" style={{ fontWeight: "unset" }}>Total Shop Income: {`$${totalIncome}.00`}</p></div>
                            </div>                            <div className="table-responsive income-scroll">
                                <table className="table">
                                    <thead className="sticky-top bg-white">
                                        <tr>
                                            <td className="text-start">
                                                <h5 className="video-text f20-size ">User Name</h5>
                                            </td>
                                            <td>
                                                <h5 className="video-text f20-size ">Date</h5>
                                            </td>
                                            <td>
                                                <h5 className="video-text f20-size  ">Income</h5>

                                            </td>
                                            <td>
                                                <h5 className="video-text f20-size  text-center">Status</h5>

                                            </td>
                                            <td>
                                                <h5 className="video-text f20-size text-center ">Shop Content</h5>

                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tiplist?.map((item) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <div className="d-flex  align-items-center justify-content-start">
                                                            <img src={item?.user_details?.profile_pic ? item?.user_details?.profile_pic : profile_image_placeholder}
                                                                className=" rounded-circle subs-img" />
                                                            <p className="subscribers ms-3 mb-0 f20-size fw-600">{item?.user_details?.name}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="subscribers f20-size fw-500 mb-0 lh-0" style={{ color: "#737373" }}>{getDate(item?.created_at)}</p>
                                                    </td>
                                                    <td>
                                                        <p className="subscribers f20-size fw-500 mb-0 lh-0" style={{ color: '#ca3392' }}>${item?.price}</p>
                                                    </td>
                                                    <td>
                                                        {
                                                            item?.status == true ?
                                                                <p className="subscribers f20-size fw-500 mb-0 lh-0 text-center" style={{ color: '#737373' }}>Completed</p> :
                                                                <p className="subscribers f20-size fw-500 mb-0 lh-0 text-center" style={{ color: '#ca3392' }}>In Processing</p>
                                                        }

                                                    </td>
                                                    <td>
                                                        <p className="subscribers f20-size fw-500 mb-0 lh-0 text-center" style={{ color: '#737373' }}>{item?.item_type == 1 ? "Series" : item?.item_type == 2 ? "Recorded Live" : item?.item_type == 3 ? "Upcoming Live" : item?.item_type == 4 ? "Image" : item?.item_type == 5 ? "Audio" : "Video"}

                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>

                                </table>
                                {
                                    hasMoreItems &&
                                    <div style={{ cursor: 'pointer' }} onClick={handleLoadMore} className="load-text mb-3">
                                        Load More...
                                    </div>
                                }
                                <div ref={loadMoreRef} ></div>

                            </div>
                        </div>
                    </div>
                </>
                    :
                    <>
                        {
                            isLoading == false && <>
                                <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                    <img className='img-fluid' src={no_tip} />
                                    <h5 className='d-flex justify-content-center mt-3'>No income yet</h5>
                                </div>
                            </>
                        }
                    </>

            }
        </>
    )
}

export default ShopIncome