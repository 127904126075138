import React, { useState } from 'react'
import { buy_btn, DEMO_IMAGE_FIVE, no_images, playBtn } from '../../Constants/images'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailModal from './detailImageModal'
import BuyModal from '../../Components/PaymentModal/BuyModal'
import ROUTES from '../../Helper/route-helper'
import { useNavigate } from 'react-router-dom'
function Images({ setTabSwitch, detailList, loading, setLoading, check, getListAPI }) {
    const [deltId, setDeltId] = useState(null)
    const [detailOpen, setDetailOpen] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})
    const navigate = useNavigate()
    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }
    const hanldeClick = (item) => {
        setDeltId(item.id)
        setDetailOpen(true)
    }

    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>

                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                {check === "download" ? (
                                    detailList.filter(item => item.is_purchase === 1).length > 0 ? (
                                        detailList.filter(item => item.is_purchase === 1).reverse().map(item => (
                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                                <div className="latest-video mx-auto">
                                                    <div className='position-relative'>
                                                        <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                            <img src={item?.shop_media[0]?.media_url} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                            <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=imgChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                                <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                                <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.name}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 py-3 schedule-grid">
                                                        <div className=''>
                                                            <h4 className="schedule-title">{item.title}</h4>                                                            <h6 className="images-count">{item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}</h6>
                                                            <p className="images-description shop-description-detail">{item.description}</p>
                                                            <span style={{ cursor: "pointer" }} onClick={() => hanldeClick(item)} className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <>
                                            <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                                <img src={DEMO_IMAGE_FIVE} alt="No Challenges" />
                                                {/* <img src={no_images} alt="no image" />
                                            <h6 className='position-absolute' style={{ bottom: 6 }}>No Images yet</h6> */}

                                            </div>
                                            <h6 className='text-center mx-5'>No images yet</h6>

                                        </>
                                    )
                                ) : (
                                    detailList.length > 0 && detailList?.reverse()?.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <img src={item?.shop_media[0]?.media_url} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                        {item.is_purchase == 0 && <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${item?.price}`} secondValue={"00"} />}
                                                        <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=imgChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                            <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                            <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-3 py-3 schedule-grid">
                                                    <div className=''>
                                                        <div className="tooltip-container">
                                                            <h4 className="schedule-title">{item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <h6 className="images-count">{item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}</h6>
                                                        <p className="images-description shop-description-detail">{item.description}</p>
                                                        {item.is_purchase == 0 ? (

                                                            <span style={{ cursor: "pointer" }} onClick={() => handleOpen(item)} className="text-end">
                                                                <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        ) : (
                                                            <span style={{ cursor: "pointer" }} onClick={() => hanldeClick(item)} className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <>
                                        <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                            <img src={DEMO_IMAGE_FIVE} alt="No Challenges" />
                                            {/* <img src={no_images} alt="no image" />
                                    <h6 className='position-absolute' style={{ bottom: 6 }}>No Images yet</h6> */}

                                        </div>
                                        <h6 className='text-center mx-5'>No images yet</h6>

                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>

                {
                    detailOpen && <AddDetailModal setTabSwitch={setTabSwitch} setDetailModal={setDetailOpen} deltId={deltId} />
                }
                {
                    buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} itemType="4" getApi={getListAPI}/>
                }

            </div>
        </>
    )
}

export default Images