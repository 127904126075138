import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from '../../ControlComponents/react-loader'
import UserBucketSeries from './series';
import UserBucketLives from './lives';
import UserBucketBites from './bites';
import UserBucketChallenges from './challenges';
import { check_blue } from '../../Constants/images';
import ActivateTier from './activateTier';
import { useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import BuyPlanModal from '../../Components/PaymentModal/BuyPlanModal';

const UserBucketDetail = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activateTier, setActivateTier] = useState(false)
    const [detail, setDetail] = useState({})
    const [dataList, setDataList] = useState([])
    const [liveList, setLiveList] = useState([])
    const [biteList, setBiteList] = useState([])
    const [challengeList, setChallengeList] = useState([])
    const location = useLocation()
   const [buyModal, setBuyModal] = useState(false)
    const tierId = location.state.id
    let message = localStorage.getItem("tierContentId")

    useEffect(() => {
        if (message) {
            if (message.includes("series")) {
                localStorage.removeItem("message")
                setTabSwitch(0)
            }
            else if (message.includes("live")) {
                localStorage.removeItem("message")
                setTabSwitch(1)
            }

            else if (message.includes("bite")) {
                localStorage.removeItem("message")
                setTabSwitch(2)
            }
            else if (message.includes("challenge")) {
                localStorage.removeItem("message")
                setTabSwitch(3)
            }
        }
        return () => {
            message = null
            localStorage.removeItem('tierContentId')

        }
    }, [])

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`tier/tier-details?tier_id=${tierId}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setDetail(result?.data)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getContentAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`tier/get-tier-content?tier_id=${tierId}&item_type=${tabSwitch == 0 ? '1' : tabSwitch == 1 ? '2' : tabSwitch == 2 ? '3' : '4'}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            if (tabSwitch == 0) {
                setDataList(result?.data)
            }
            else if (tabSwitch == 1) {
                setLiveList(result?.data)
            }
            else if (tabSwitch == 2) {
                setBiteList(result.data)
            }
            else if (tabSwitch == 3) {
                setChallengeList(result?.data)
            }
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
        getContentAPI()
    }, [tabSwitch, refresh])

    const getBuyApi = async () => {
        let data;
        data = {
            "tier_id": tierId,
            "channel_id": detail?.creator_id,
            "transaction_id": "358oih90876eyrc"
        }

        setIsLoading(true)
        let result = await apiHelper.postService('tier/activate-tier', data)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setActivateTier(false)
            getDetailsAPI()
            getContentAPI()
            NotificationManager.success(result.message)

        }
        else {
            setIsLoading(false)
            NotificationManager.error(result.message)
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className="bucket-inner p-3">
                    <div className='row align-items-center'>
                        <div className='col-sm-5 ps-4'>
                            <div className="">
                                <h3 className="fw-600">Plan Detail</h3>
                            </div>
                        </div>
                        <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                            {
                                detail.is_activated == 0 ?
                                    <>{
                                        detail.plan_type == 'free' ?

                                            <button className="common-btn" onClick={() => {
                                                setActivateTier(true)
                                            }}> Activate Plan</button> :

                                            <button className="common-btn" onClick={() => {
                                                setBuyModal(true)
                                            }}> Buy Plan</button>
                                    }</> :
                                    <>{
                                        detail.plan_type == 'free' ?


                                            <button className="common-btn btn-tier grey-tier" >Activated  </button> :

                                            <button className="common-btn btn-tier grey-tier" >  <img src={check_blue} /> Purchased</button>
                                    }</>
                            }

                        </div>
                    </div>
                    <div className='row align-items-center mt-3'>
                        <div className='col-sm-5 ps-4'>
                            <div className="getaccess_text">
                                <h2 className="fw-600 channel-text">{detail.plan_name}</h2>
                            </div>
                            {
                                detail.is_activated == 0 ?
                                    <>  {
                                        detail.plan_type == 'free' ?
                                            <div className='d-flex my-3 gap-3 '>
                                                <div className='tier-month'>
                                                    <p className="channel-para mb-0 text-pink">Unlimited</p>
                                                </div>

                                            </div> :
                                            <div className='d-flex my-3 gap-3 '>
                                                <div className='tier-month'>
                                                    <p className="channel-para mb-0 text-pink">{detail.duration}  {detail?.duration == 1 ? 'month':'months' }</p>
                                                </div>
                                               
                                            </div>
                                    }</> :
                                    <>  {
                                        detail.plan_type == 'free' ?
                                            <div className='d-flex my-3 gap-3 '>
                                                <div className='tier-month'>
                                                    <p className="channel-para mb-0 text-pink">Unlimited</p>
                                                </div>

                                            </div> :
                                            <div className='d-flex my-3 gap-3 '>
                                                <div className='tier-month'>
                                                    <p className="channel-para mb-0 text-pink">{detail.duration} Months</p>
                                                </div>
                                                <div className='tier-date'>
                                                    <p className="text-black mb-0">Expiry date : {formatDate(detail.expiry_date)}</p>
                                                </div>
                                            </div>
                                    }</>

                            }

                        </div>
                        {
                            detail.plan_type == 'free' ?
                                <div className='col-sm-7 ms-auto text-end d-flex justify-content-end'>
                                    <h3 class="text-pink pt-3 number-count">Free</h3>
                                </div> :
                                <div className='col-sm-7 ms-auto text-end d-flex justify-content-end'>
                                    <h3 class="text-pink pt-3 number-count"> ${parseFloat(detail?.price).toFixed(2).split('.')[0]}.<span class="fw-600 bucket-price">{parseFloat(detail?.price).toFixed(2).split('.')[1]}</span></h3>

                                </div>
                        }
                        <p className="tier-description">{detail.description}</p>
                    </div>
                    <section className="streaming py-6 response-padding ">
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    detail.plan_type == 'free' ?
                                        <div className="stream-btn pt-3">
                                            <button onClick={() => setTabSwitch(0)} className={`common-btn ${tabSwitch == 0 ? "btn-blue" : "btn-white"}`}>Series</button>
                                            <button onClick={() => setTabSwitch(1)} className={`common-btn ${tabSwitch == 1 ? "btn-blue" : "btn-white"}`}>Recorded Lives</button>
                                            <button onClick={() => setTabSwitch(2)} className={`common-btn ${tabSwitch == 2 ? "btn-blue" : "btn-white"}`}>Bites</button>
                                            <button onClick={() => setTabSwitch(3)} className={`common-btn ${tabSwitch == 3 ? "btn-blue" : "btn-white"}`}>Challenges</button>

                                        </div> :
                                        <div className="stream-btn pt-3">
                                            <button onClick={() => setTabSwitch(0)} className={`common-btn ${tabSwitch == 0 ? "btn-blue" : "btn-white"}`}>Series</button>
                                            <button onClick={() => setTabSwitch(1)} className={`common-btn ${tabSwitch == 1 ? "btn-blue" : "btn-white"}`}>Recorded Lives</button>

                                        </div>
                                }

                            </div>
                        </div>
                    </section>
                    {tabSwitch == 0 ?
                        <>
                            <UserBucketSeries dataList={dataList} detail={detail}/>
                        </> :
                        tabSwitch == 1 ?
                            <UserBucketLives dataList={liveList}  detail={detail} />
                            :
                            tabSwitch == 2 ?
                                <UserBucketBites dataList={biteList}  detail={detail}/>
                                :
                                <UserBucketChallenges dataList={challengeList}  detail={detail}/>

                    }
                </div>
            </div>
            {
                activateTier &&
                <ActivateTier setActivateTier={setActivateTier} getBuyApi={getBuyApi} item={detail} />
            }
             {
                        buyModal && <BuyPlanModal setBuyModal={setBuyModal} item={detail} setLoading={setIsLoading} getApi={getDetailsAPI} channelId={detail?.creator_id} />
                    }
        </div>
    )
}

export default UserBucketDetail;