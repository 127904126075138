import React, { useEffect, useState } from 'react'
import { access, arrowbg, dotsbg, dummy, graphicon, playbg, profile_image_placeholder, subscribeicon, tincome, tipsbox, videoicon } from '../../Constants/images'
import "./dashboard.css"
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'
import FilterDatePicker from '../component/filterDatePicker'
import { Dropdown } from 'react-bootstrap'

function Dashboard() {
  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const [fields, setFields] = useState({
    from: '',
    to: ""
  })
  const [filter, setFilter] = useState(false)

  async function getAPI() {
    setIsLoading(true)
    let url;
     if (fields.to && fields.from) {
      url = `creator/dashboard-data?start_date=${fields.from}&end_date=${fields.to}`
    }
    else {
      url = `creator/dashboard-data`
    }
    let result = await apiHelper.getRequest(url)
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setIsLoading(false)
      setData(result?.data)
      if (fields.to && fields.from) {
        setFilter(true);
      } else {
        setFilter(false);
      }
    }
    else if (result?.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }
  async function getClear() {
    setIsLoading(true)
    let url;
      url = `creator/dashboard-data`
    
    let result = await apiHelper.getRequest(url)
    if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
      setIsLoading(false)
      setData(result?.data)    
        setFilter(false); 
     setFields({ from: "", to: "" }); 
    }
    else if (result?.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }


  useEffect(() => {
    getAPI()
  }, [refresh])

  const handleView = (id) => {
    localStorage.setItem("series_id", id)
    navigate(ROUTES.CREATER_SERIES_DETAIL)
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const setDate = (newDate) => {
    setFields((prevFields) => ({
      ...prevFields,
      from: newDate,
    }));
  };
  const setToDate = (newDate) => {
    setFields((prevFields) => ({
      ...prevFields,
      to: newDate,
    }));
  };

  const handleClick = () => {
    if (fields.to == "" || fields.from == "") return
    getAPI()
  }
  const handleClear =  () => {
    
    getClear(); 
  };

  return (

    <div className="main-inner-content">
      {isLoading && <ReactLoader />}
      <div className='d-flex justify-content-between mb-3'>
        <h3 className='fw-600 mb-0'>Quick Access</h3>
        <div className='d-flex justify-content-between gap-4'>
          <div className='d-flex gap-2'>
            <div className='fw-600 mb-0 f20-size mt-2'>From:</div>
            <div className='filter'>
              <FilterDatePicker
                value={fields.from}
                setFn={setDate}
                className="border-edit w-100 "
              />
            </div>
          </div>
          <div className='d-flex gap-2'>
            <div className='fw-600 mb-0 f20-size mt-2'>To:</div>
            <div className='tooltip-container'>
              <div className='filter'>
                <FilterDatePicker
                  value={fields.to}
                  setFn={setToDate}
                  className="border-edit w-100"
                /></div>
              <span className="tooltip-text">Select same date for per day count</span>
            </div>

          </div>
          {
            !filter ? <>{
              fields.to != "" && fields.from != "" ?
                <button className={"common-btn"} style={{ cursor: "pointer", padding: "13px 43px" }} onClick={() => handleClick()}>Submit</button>
                : <button className={"common-btn-grey"} style={{ cursor: "not-allowed", padding: "13px 43px" }}>Submit</button>

            }</> :
              <button className={"common-btn"} style={{ cursor: "pointer", padding: "13px 43px" }} onClick={() => handleClear()}>Clear</button>
          }

        </div>
      </div>
      <div className="row " style={{ cursor: 'pointer' }}>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6 "><img src={tincome} className="icon-background" />
              </div>
              <div className="col-6 text-end"><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Total Income
              {/* <span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>10%</span> */}
            </h5>
            <div className="d-flex justify-content-between justify-content-center mt-3">
              <h3 className=" fw-600">${data?.analytics?.totalIncome?data?.analytics?.totalIncome:"0"}</h3>
              <img src={arrowbg} onClick={() => navigate(ROUTES.INCOME)} className="next-icon" />
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6 "><img src={subscribeicon} className="icon-background" />
              </div>
              <div className="col-6 text-end "><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Followers
              {/* <span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>5%</span> */}
            </h5>
            <div className="d-flex justify-content-between  mt-3">
              <h3 className="fw-600">{data?.analytics?.totalFollowers}</h3>
              <img src={arrowbg} className="next-icon" onClick={() => navigate(ROUTES.CREATER_SUBSCRIBERS)} />
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6  "><img src={tipsbox} className="icon-background" />
              </div>
              <div className="col-6 text-end"><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Tips
              {/* <span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>20%</span> */}
            </h5>
            <div className="d-flex justify-content-between  mt-3">
              <h3 className=" fw-600">{data?.analytics?.totalTips ? data?.analytics?.totalTips : "0"}</h3>
              <img src={arrowbg} className="next-icon" onClick={() => navigate(ROUTES.TIPS_CREATOR)} />
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6 "><img src={videoicon} className="icon-background" />
              </div>
              <div className="col-6 text-end"><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Uploaded Videos
              {/* <span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>10%</span> */}
            </h5>
            <div className="d-flex justify-content-between  mt-3">
              <h3 className="fw-600">{data?.analytics?.totalUploadedVideos}</h3>
              <img src={arrowbg} className="next-icon" onClick={() => navigate(ROUTES.CREATER_SERIES)} />
            </div>
          </div>
        </div>

      </div>
      <div className='row mt-4'>
        <div className='col-lg-6  col-xxl-4 mb-3 mb-xxl-0'>
          <div className='bucketvideo_wrapper bg-white p-3'>
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600 mb-0'>Uploaded Videos</h3>
              <a className='cursor-pointer text-pink f16-size fw-600' onClick={() => navigate(ROUTES.CREATER_SERIES)}>See all</a>
            </div>
            <ul className='uploadedvideo_listed ps-0 '>
              {
                data?.uploadedVideos?.slice(-5).reverse()?.map((item) => (
                  <li className='pb-3' key={item?.id} >
                    <div className='upload_inner border-bottom pb-3'>
                      <div className='upload-profile'>
                        <img src={item?.thumbnail}></img>
                      </div>
                      <div className='upload-text'>
                        <h4 className='mb-1'>{item?.title}</h4>
                        <p className='mb-0 lh-base f16-size fw-500 video-text' >{item?.duration}</p>
                        <p className='mb-0 lh-base f16-size fw-500 video-text'>{item?.episodesCount} {item?.episodesCount == 1 ? "Episode" : "Episodes"}</p>
                      </div>
                      <div style={{ cursor: "pointer" }} onClick={() => handleView(item.id)}>
                        <img src={playbg}></img>
                      </div>
                    </div>
                  </li>
                ))
              }

            </ul>
          </div>
        </div>
        <div className='col-lg-6  col-xxl-4 mb-3 mb-xxl-0'>
          <div className='bucketvideo_wrapper bg-white p-3'>
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600 mb-0'>Plans</h3>
              <a className='cursor-pointer text-pink f16-size fw-600' onClick={() => navigate(ROUTES.CREATER_BUCKETS)}>See all</a>
            </div>
            <ul className='uploadedvideo_listed ps-0 '>
              {
                data?.plansData?.slice(-5)?.reverse()?.map((item) => (
                  <li className='position-relative pb-3' key={item.id} style={{ cursor: 'pointer' }} onClick={() => {
                    navigate(ROUTES.CREATER_BUCKET_ACCESS, {
                        state: {
                            item: item
                        }
                    })
                    localStorage.setItem('tierItem', JSON.stringify(item))

                }
                } >
                    <div className='bucketdash_inner'>
                      <div className='upload-profile'>
                        <img src={item?.image}></img>
                      </div>
                      <div className='upload-text'>
                        <h4 className='my-1'>{item?.plan_name}</h4>
                        <div className='mb-0 f16-size fw-500 video-text'  >{item.description?.length > 77 ? item.description.substring(0, 77) + "..." : item.description}</div>
                      </div>
                      <div className='bucketprice'>
                        {
                          item.price == "0.00" ? <p className='mb-0'>Free</p>
                            :
                            <p className="mb-0">
                              ${Math.floor(item.price)}<span className="f14-size">.{(item.price % 1).toFixed(2).split(".")[1]}</span>
                            </p>
                        }
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        <div className='col-lg-12  col-xxl-4 mb-3 mb-xxl-0'>
          <div className='bucketvideo_wrapper bg-white p-3'>
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600 mb-0'>Tips</h3>
              <a className='cursor-pointer text-pink f16-size fw-600' onClick={() => navigate(ROUTES.TIPS_CREATOR)}>See all</a>
            </div>
            <ul className='tipsdash_wrapper ps-0 mb-0 uploadedvideo_listed'>
              {data?.tipsData?.slice(-5)?.reverse()?.map((item) => (
                <li className='pb-3' key={item?.id}>
                  <div className='upload_inner border-bottom pb-3'>
                    <div className='upload-profile'>
                      <img src={item.user_details.profile_pic ? item.user_details.profile_pic : profile_image_placeholder}></img>
                    </div>
                    <div className='upload-text'>
                      <h4 className='mb-1 fw-600'>{item.user_details.name}</h4>
                    </div>
                    <div className='tipprice'>
                      <p className='mb-0'>${Math.floor(item.price)}<span className='f14-size'>.{(item.price % 1).toFixed(2).split(".")[1]}</span></p>
                    </div>
                  </div>
                </li>
              ))}


            </ul>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <div className="subscribers-table">
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600'>Followers</h3>
              <a className='cursor-pointer text-pink f16-size fw-600' onClick={() => navigate(ROUTES.CREATER_SUBSCRIBERS)}>See all</a>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead className="sticky-top bg-white">
                  <tr>
                    <td className="text-start">
                      <h5 className="video-text f20-size ">Name</h5>
                    </td>
                    <td>
                      <h5 className="video-text f20-size ">Tips</h5>
                    </td>
                    <td>
                      <h5 className="video-text f20-size text-center ">Plan History</h5>

                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.followers?.slice(-5).reverse()?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="d-flex  align-items-center justify-content-start">
                            <img src={item?.profile_pic ? item?.profile_pic : profile_image_placeholder}
                              className=" rounded-circle subs-img" />
                            <p className="subscribers ms-3 mb-0 f20-size fw-500">{item.name}</p>
                          </div>
                        </td>

                        <td>
                          <p className="subscribers f20-size fw-500 mb-0 lh-0">{item.total_tips}</p>
                        </td>
                        <td className='text-center'>
                          <Dropdown>
                            <Dropdown.Toggle id="custom-dropdown-toggle" className='view-plan'>
                              <button
                                className="btn text-white view-btn text-center">View</button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='record-card-dropdown view-drop' >
                              {
                                item?.tiers_data?.map((item) => (
                                  <div className='d-flex justify-content-between gap-4 ' >
                                    <div>
                                      {item?.plan_name}
                                    </div>
                                    <div>{formatDate(item.expiry_date)}</div>
                                  </div>

                                ))
                              }


                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard