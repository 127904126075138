import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { buy_btn, DEMO_IMAGE_EIGHT, DEMO_IMAGE_THREE, live_calender, live_clock, no_live, playBtn, playGrey } from '../../Constants/images';
import ReactLoader from '../../ControlComponents/react-loader';
import ROUTES from '../../Helper/route-helper';
import PriceTag from '../../CreaterDashboard/component/price-tag';

function GiftUpcoming({ setTabSwitch, detailList, loading, setLoading, getListAPI, check }) {
    const navigate = useNavigate();
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    };

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        originalDateTime.setHours(originalDateTime.getHours() + 5);
        originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    const hanldeClick = (item) => {
        if (localStorage.getItem("role") == 2) {
            if (item.status == "0") {
                localStorage.setItem("item", JSON.stringify(item))
                navigate(ROUTES.CREATER_SHOP_RECORDED_VIDEO)

            }
        }
        else {
            if (item.status == "0") {
                localStorage.setItem("item", JSON.stringify(item))
                navigate(ROUTES.USER_RECORDED_VIDEO)

            }
        }

    };


    const isCurrentDateTime = (dateTime) => {
        const itemDateTime = new Date(dateTime);
        return itemDateTime <= currentDateTime && itemDateTime >= currentDateTime;
    };

    return (
        <>
            {loading && <ReactLoader />}
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                {
                                    detailList?.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3">
                                                        <div className="position-relative">
                                                            <img src={item.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid mb-2">
                                                    <div className='d-flex justify-content-between flex-column'>
                                                        <div className="tooltip-container">
                                                            <h4 className="schedule-title mb-0">{item.name}</h4>
                                                            <span className="tooltip-text">{item.name}</span>
                                                        </div>

                                                        <div className="timing-flex" style={{ width: 150 }}>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_calender} alt="calendar" />
                                                                <span className='ps-1'>{dateFunction(item.date_time)}</span>
                                                            </div>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_clock} alt="clock" />
                                                                <span className='ps-1'>{addTime(item.date_time)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dotschedule bg-transparent'>
                                                        <div className='dropdownschedule'>

                                                            <span style={{ cursor: "pointer" }}
                                                                className="text-end">
                                                                {
                                                                    isCurrentDateTime(item.date_time) ? <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} onClick={() => hanldeClick(item)} /> : <img src={playGrey} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                }
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        ) : (
                            <>
                                {loading === false && (
                                    <>
                                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                            <img src={DEMO_IMAGE_EIGHT} alt="No Challenges" />
                                        </div>
                                        <h6 className='text-center'>No lives yet</h6>
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>

            </div>
        </>
    );
}

export default GiftUpcoming;
