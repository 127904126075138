import React, { useState } from 'react'
import { buy_btn, playBtn, profile_image_placeholder } from '../../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';
import PriceTag from '../../../../CreaterDashboard/component/price-tag';
import BuyModal from '../../../../Components/PaymentModal/BuyModal';

function Recomended(props) {
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({})
  const navigate = useNavigate()

  return (
    <div>
      <section className="recoomended-main-cus recomended response-padding mt-5">
        <div className="container">
          <div className="chanel-header">
            <h3 className="fw-600 mb-0">Recomended</h3>
          </div>
          <div className="chanel-wrapper mt-4" style={{ cursor: 'pointer' }}>
            <div className="row one-row-scroll">
              {
                props?.featuredList?.data?.map((featured) => {
                  return (
                    <div key={featured?.id} className="col-md-6 col-xxl-4 response-col ">
                      <div className="chanel-content dis-recommend-cus">
                        <div className="bite-relative position-relative">
                          <img
                            src={featured?.thumbnail}
                            className="bite-img"
                            alt=""
                          />
                          {
                            (featured.is_purchase == 0 && featured.tier_details == null) &&
                            <PriceTag classes={"price_tag series-tag"} firstValue={`$${featured?.price}`} secondValue={"00"} />
                          }
                        </div>
                        <div className="bite-wrapper">
                          <div className='tooltip-container'>
                            <h4>{featured?.title}</h4>
                            <span className="tooltip-text">{featured.title}</span>
                          </div>

                          <div className="chanel-text d-flex gap-3 align-items-center">
                            <img src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder} className="series-inner-img" alt="" onClick={() => {
                              navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: featured } });
                            }} />
                            <div>
                              <h6 className='fw-600 mb-0' onClick={() => {
                                navigate(ROUTES.CHANNELS_DETAILS + "?cd=homeChannel", { state: { item: featured } });
                              }} >{featured?.channelDetail?.name ? featured?.channelDetail?.name : profile_image_placeholder}</h6>
                              <p className='mt-1' onClick={() => navigate(ROUTES.CATEGORY, { state: { id: featured?.category?.id, catName: featured?.category?.name } })}>{featured?.category?.name}</p>
                            </div>
                          </div>
                          <p>
                            {featured?.episodes_count} {featured.episodes_count == 1 ? "Episode" : "Epsiodes"}</p>
                        </div>


                        {
                          featured.tier_details == null ?
                            <>
                              {
                                featured.is_purchase == 0 ?
                                  <div className='bites-recoomend-bottom'>
                                    <img
                                      onClick={() => {
                                        setOpen(true)
                                        setItem(featured)
                                      }}
                                      src={buy_btn}
                                      className="bite-inner-img"
                                      alt=""
                                    />
                                  </div> :
                                  <div className='bites-recoomend-bottom'>
                                    <img
                                      onClick={() => {
                                        navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: featured?.id } })

                                      }}
                                      src={playBtn}
                                      className="bite-inner-img"
                                      alt=""
                                    />
                                  </div>
                              }
                            </>
                            :
                            <div className='bites-recoomend-bottom'>
                              <img
                                onClick={() => {

                                  if (featured.tier_details?.is_purchased == 1) {
                                    navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: featured?.id } })
                                  }
                                  else {
                                    localStorage.setItem('tierContentId', featured.id + 'series')
                                    navigate(ROUTES.USER_BUCKET_DETAIL, { state: { id: featured.tier_details.tier_id } })
                                  }
                                }}
                                src={playBtn}
                                className="bite-inner-img"
                                alt=""
                              />
                            </div>
                        }
                      </div>
                    </div>
                  )

                })
              }

            </div>
          </div>
        </div>
        
          {open && <BuyModal item={item} setLoading={props.setIsLoading} setBuyModal={setOpen} itemType="1" getApi={props.getAPI} />}
      </section>
    </div>
  )
}

export default Recomended;