import React, { useState, useEffect, useRef } from 'react';
import AgoraRTC, {
    ICameraVideoTrack,
    IRemoteVideoTrack,
    IAgoraRTCClient,
    IRemoteAudioTrack,
} from "agora-rtc-sdk-ng"
import { AGORA_CRED } from '../../Helper/config';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import { live_icon, profile_image_placeholder, send, smile, tip } from '../../Constants/images';
import ReactLoader from '../../ControlComponents/react-loader';
import "./userLives.css"
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { collection, addDoc, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import EmojiPicker from 'emoji-picker-react';
import TipModal from '../Buy/tipModal';

export const VideoPlayer = ({ videoTrack, handleLeave, audioTrack, isLoading, creatorName }) => {
    const ref = useRef(null)
    useEffect(() => {
        const playerRef = ref.current;
        if (!videoTrack) return;
        if (!playerRef) return;
        videoTrack.play(playerRef)
        return () => {
            videoTrack.stop();
        };
    }, [videoTrack])

    function getFirstNameLastNameAbbreviation(fullName) {
        const nameParts = fullName.split(" ");
        let firstNameInitial = nameParts[0].charAt(0).toUpperCase();
        let lastNameInitial = '';
        if (nameParts.length > 1) {
            lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
        }
        return firstNameInitial + lastNameInitial
    }



    return (
        <>
            {
                videoTrack == null && audioTrack == null && isLoading == false ? <div className='live-video'>
                    <div className='mutedvideo'>
                        <p className='mb-0'>{getFirstNameLastNameAbbreviation(creatorName)}</p>
                    </div>
                    <div className='audiomuted bothmutedstyle'>
                        <p className='mb-0'>Audio Muted</p>
                    </div>
                    <div className='livetime-button' style={{ cursor: "pointer" }} onClick={handleLeave}>
                        Leave
                    </div>
                    <div className='miccameramain'>
                        <div className='micmain'>
                            <img src={live_icon} /> Live</div>
                    </div>
                </div> :
                    videoTrack == null && isLoading == false ?
                        <div className='live-video'>
                            <div className='mutedvideo'>
                                <p className='mb-0'>{getFirstNameLastNameAbbreviation(creatorName)}</p>
                            </div>

                            <div className='livetime-button' style={{ cursor: "pointer" }} onClick={handleLeave}>
                                Leave
                            </div>
                            <div className='miccameramain'>
                                <div className='micmain'>
                                    <img src={live_icon} /> Live</div>
                            </div>
                        </div>
                        : audioTrack == null && isLoading == false ?
                            <div ref={ref} className='live-video'>
                                <div className='audiomuted '>
                                    <p className='mb-0'>Audio Muted</p>
                                </div>
                                <div className='livetime-button' style={{ cursor: "pointer" }} onClick={handleLeave}>
                                    Leave
                                </div>
                                <div className='miccameramain'>
                                    <div className='micmain'>
                                        <img src={live_icon} /> Live</div>
                                </div>
                            </div> :
                            <div ref={ref} className='live-video'>
                                <div className='livetime-button' style={{ cursor: "pointer" }} onClick={handleLeave}>
                                    Leave
                                </div>
                                <div className='miccameramain'>
                                    <div className='liveiconlive'>
                                        <img src={live_icon} className='me-1' /> Live</div>
                                </div>
                            </div>
            }
        </>
    );

}

const LiveSection = () => {
    const [expandedComments, setExpandedComments] = useState([]);
    const messageListRef = useRef();
    const [startTime, setStartTime] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [tipOpen, setTipOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const [rtcToken, setRtcToken] = useState(location.state.item.agora_detail?.token);
    const [channelName, setChannelName] = useState(location.state.item.agora_detail?.channel);
    const [themVideo, setThemVideo] = useState(null);
    const [themAudio, setThemAudio] = useState(null);
    const [client, setClient] = useState(null)
    const [creatorName, setCreatorName] = useState(location?.state?.item?.name)
    const [userName, setUserName] = useState(null)
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [title, setTitle] = useState(location?.state?.item?.schedule?.name)
    const [description, setDescription] = useState(location?.state?.item?.schedule?.description)
    const [isLoading, setIsLoading] = useState(false)
    const [creatorImg, setCreatorImg] = useState(null)
    const chatId = localStorage.getItem("chatId") ? localStorage.getItem("chatId") : localStorage.getItem("chatId")
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
        return date.toLocaleString('en-US', options);
    }

    async function getDetailsAPI() {
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setUserName(result?.data?.name);
            setCreatorImg(result.data.profile_pic)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult?.data?.token)
                localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (messageListRef.current) {
            const { scrollHeight, clientHeight } = messageListRef.current;
            messageListRef.current.scrollTop = scrollHeight - clientHeight
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [])

    useEffect(() => {
        const connectToRtc = async () => {
            if (rtcToken && channelName) {
                setIsLoading(true)
                const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
                setClient(client)
                await client.join(AGORA_CRED.APP_ID, channelName, rtcToken, null)
                client.on("user-published", async (themUser, mediaType) => {
                    client.subscribe(themUser, mediaType).then(() => {
                        setIsLoading(false)
                        if (mediaType === "video") {
                            setThemVideo(themUser.videoTrack)
                        }
                        if (mediaType === "audio") {
                            setThemAudio(themUser.audioTrack)
                            themUser.audioTrack.play()
                        }
                    })
                    setStartTime(formatTimestamp(location.state.item.schedule.date_time));
                })
                client.on("user-unpublished", (themUser) => {
                    if (themUser._audio_muted_ == true) {
                        setThemAudio(null)
                    }
                    if (themUser._video_muted_ == true) {
                        setThemVideo(null)
                    }
                    if (themUser?._video_added_ == false) {
                        setThemAudio(null)
                        client.leave()
                        setThemVideo(null)
                        setChannelName(null)
                        setRtcToken(null)
                        client.removeAllListeners()
                        if (localStorage.getItem("liveHome") == 0) {
                            navigate(ROUTES.HOME)
                        }
                        else {
                            navigate(ROUTES.LIVES)

                        } const message = "creator ended the live stream"
                        localStorage.setItem("message", message)
                        localStorage.removeItem("chatId")
                    }
                })
            }
        }
        connectToRtc()
        return () => {
            if (client) {
                client.leave();
                client.removeAllListeners();
                setClient(null);
                setThemAudio(null);
                setThemVideo(null);
                setChannelName(null)
                setRtcToken(null)
                localStorage.removeItem("chatId")
                localStorage.removeItem("message", message)
            }
        };
    }, [rtcToken, channelName])

    const handleLeave = async () => {
        localStorage.removeItem("message", message)
        client.leave();
        client.removeAllListeners();
        setThemVideo(null)
        localStorage.removeItem("chatId")
        setThemAudio(null)
        setChannelName(null)
        setRtcToken(null)
        if (localStorage.getItem("liveHome") == 0) {
            navigate(ROUTES.HOME)
        }
        else {
            navigate(ROUTES.LIVES)
        }
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        const commentUid = uuidv4();

        if (message != "") {
            try {
                const currentTime = new Date().getTime();
                let startTime = location.state.item.schedule.date_time;
                startTime = new Date(startTime);
                startTime.setHours(startTime.getHours() + 5);
                startTime.setMinutes(startTime.getMinutes() + 30);
                const startTimeDate = startTime.getTime();
                const elapsedTimeMilliseconds = currentTime - startTimeDate;
                await addDoc(collection(db, "LiveStreamCollection", chatId, 'Comments'), {
                    name: userName,
                    comment: message,
                    createdAt: new Date(Date.now()).toString(),
                    timeStamp: elapsedTimeMilliseconds,
                    profile_pic: creatorImg,
                    uuid: commentUid,

                });
                localStorage.setItem("value", " ")
                setMessage("");
            } catch (e) {
            }
        }
    }


    useEffect(() => {
        const q = query(collection(db, "LiveStreamCollection", chatId, "Comments"), orderBy("createdAt", "asc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedMessages = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(fetchedMessages);
        });
        return () => unsubscribe();
    }, []);

    function getTimeAgo(createdAt) {
        const currentDate = new Date();
        const createdAtDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdAtDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return days + (days === 0 || days === 1 ? " day ago" : " days ago");
        } else if (hours > 0) {
            return hours + (hours === 0 || hours === 1 ? " hour ago" : " hours ago");
        } else if (minutes > 0) {
            return minutes + (minutes === 0 || minutes === 1 ? " min ago" : " mins ago");
        } else {
            return (seconds >= 0 ? seconds : 0) + " sec ago";
        }
    }
    const renderCommentText = (text, uuid) => {
        const isExpanded = expandedComments.includes(uuid);

        if (text.length > 200 && !isExpanded) {
            return (
                <>
                    {`${text.substring(0, 200)}...`}
                    <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleExpandComment(uuid)}> Read More</span>
                </>
            );
        } else if (isExpanded) {
            return (
                <>
                    {text}
                    <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleCollapseComment(uuid)}> ...Read Less</span>
                </>
            );
        } else {
            return text;
        }
    };

    const handleExpandComment = (uuid) => {
        setExpandedComments(prevState => [...prevState, uuid]);
    };

    const handleCollapseComment = (uuid) => {
        setExpandedComments(prevState => prevState.filter(item => item !== uuid));
    };
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };

    const handleEmojiSelect = (emoji) => {
        const valueMessage = localStorage.getItem("value")
        setMessage(valueMessage + emoji.emoji)
        localStorage.setItem("value", valueMessage + emoji.emoji)
    }

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row'>
                    <>
                        <div className='col-lg-8 order-one'>
                            <VideoPlayer
                                videoTrack={themVideo}
                                audioTrack={themAudio}
                                handleLeave={handleLeave}
                                isLoading={isLoading}
                                creatorName={creatorName}
                            />
                        </div>
                        <div className='col-lg-4 mt-4 mt-md-0 order-four'>
                            <div className='h-100 cus-series-chat cus-live-chat'>
                                <div className='chat-right position-relative'>
                                    <div className='chat-heading'>
                                        <h4 className='mb-0'>Chats</h4>
                                    </div>
                                    <div className='chat-message userchat-message' ref={messageListRef} >
                                        <div className='userchat-container'>
                                            {
                                                messages.map((msg, index) => (
                                                    <div className='chat-wrapper border-bottom pb-3' key={index} >
                                                        <div className='chat-listing py-3'>
                                                            <div className='profile-chat'>
                                                                {
                                                                    msg.profile_pic ? <img src={msg.profile_pic}></img>
                                                                        :
                                                                        <img src={profile_image_placeholder} />
                                                                }                                                    </div>
                                                            <div className='d-flex align-items-start'>
                                                                <p className='mb-0 lh-sm f16-size fw-500  text-capitalize side_text user-sidetext'>{msg.name}{" "}:</p>
                                                                <p className='mb-0 channel-text f16-size fw-600 user-side-comment'> {renderCommentText(msg.comment, msg.uuid)}</p>
                                                            </div>
                                                            <p className='user-side-day'>{getTimeAgo(msg.createdAt)}</p>
                                                            {/* <div className=''>
                                                                <img src={dotshorizontal}></img>
                                                                </div> */}
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <form onSubmit={sendMessage}>
                                            <div className='chat-bottom custom-chat-bottom drop-bottom'>
                                                <img src={creatorImg ? creatorImg : profile_image_placeholder} className='profile-bottom'></img>
                                                <input
                                                    id="input-message"
                                                    className="input-message w-100 border-0"
                                                    type="text"
                                                    placeholder="Type a Message"
                                                    value={message}
                                                    onChange={(e) => {
                                                        setMessage(e.target.value)
                                                        localStorage.setItem("value", e.target.value)
                                                    }
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (e.key == "Enter" || e.key == "numpndEnter") {
                                                            sendMessage(e)
                                                        }
                                                        if (e.code == "Backspace") {
                                                            const valueMessage = localStorage.getItem("value")
                                                            if (valueMessage?.length >= 0) {
                                                                setMessage(valueMessage.slice(0, -1))
                                                            }
                                                        }
                                                    }} />
                                                {/* <button style={{ padding: "8px 18px" }}
                                                    className={` btn-blue left-corner`} onClick={() => setTipOpen(true)}>
                                                    Tip
                                                </button> */}

                                                <img src={tip} style={{width:'43px'}}
                                                    onClick={() => { setTipOpen(true) }}></img>
                                                <img src={send}
                                                    onClick={sendMessage}
                                                ></img>
                                                <img src={smile} onClick={toggleEmojiPicker} ></img>
                                                {showEmojiPicker && (
                                                    <EmojiPicker
                                                        onEmojiClick={handleEmojiSelect}
                                                        disableAutoFocus={true}
                                                        className='custome-emoji'
                                                        pickerStyle={{
                                                            position: 'absolute',
                                                            bottom: '50px',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    <div className="livechattitle pt-3 order-two">
                        <h3 className='fw-600 text-capitalize'>{title}</h3>
                    </div>
                    <div className='livechatdescription order-three'>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
            {
                tipOpen && <TipModal setTipOpen={setTipOpen} userId={location?.state?.item?.schedule.creator_id} />
            }
        </div>
    );
};

export default LiveSection;
