import React, { useState } from 'react'
import { playLightbtn } from '../../Constants/images';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from 'react-router-dom';
import SeriesVideoPlayer from '../../Components/VideoPlayer/seriesVideoPlayer';
import ROUTES from '../../Helper/route-helper';
import AddTrailor from './addTrailor';
import uploadToS3 from '../component/fileUpload';
import ERR_MESSAGE from '../../Helper/error-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import { NotificationManager } from 'react-notifications';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"

function Trailors(props) {
  const [selectedOption, setSelectedOption] = useState(1);
  const [isLoading, setLoading] = useState(false)
  const [video, setVideo] = useState('')
  const [show, setShow] = useState(false)
  const [id, setId] = useState(null)
  const [addTrailorModal, setAddTrailorModal] = useState(false)
  const navigate = useNavigate()
  const [thumbnail, setThumbnail] = useState("")
  const [errors, setErrors] = useState("")
  const [clipData1, setClipData1] = useState([]);
  const [trailerData1, setTrailerData1] = useState([])
  const [progressLoading, setProgressLoading] = useState(false)
  const [progress, setProgress] = useState();

  const handleOptionChange = (event) => {
    setSelectedOption(Number(event.target.value));
  };
  const fileUpload2 = async (thumb, file, formattedDuration) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {

          setProgressLoading(false)
          setThumbnail(state.thumbnailLocation)
          setClipData1(prevData => [
            ...prevData,
            {
              video: state.videoLocation,
              thumbnail: state.thumbnailLocation,
              duration: formattedDuration,
              type: 2,
              is_active: 1,
            }
          ]
          )
        }
        ;
      }
    }
    catch (error) {
    }
  };

  const fileChangeClip = async (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files;
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.addEventListener('loadedmetadata', async () => {
          const durationInSeconds = videoElement.duration;
          const formattedDuration = formatDuration(durationInSeconds);
          const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
          const formData = new FormData();
          formData.append('mediaFile', file);
          const videoURL = URL.createObjectURL(file);
          fileUpload2(fileThumbnail, file, formattedDuration)

        });
        videoElement.src = URL.createObjectURL(file);
      }
    }

  };
  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  function modifyDuration(duration) {
    const durationParts = duration?.split(':');
    if (durationParts && durationParts[0] === '00') {
      return durationParts.slice(1)?.join(':');
    }
    return duration;
  }

  const generateThumbnail = async (videoElement, files) => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoElement.videoWidth;
    const videoHeight = videoElement.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
    const thumbnailURL = canvas.toDataURL('image/jpeg');
    const fileThumbnail = dataURLtoFile(thumbnailURL, files[0].name.split(".")[0]);
    return { thumbnailURL, fileThumbnail };
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
    return new Promise((resolve) => {
      const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
      videoElement.currentTime = seekTime;
      videoElement.addEventListener('seeked', async () => {
        const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
        resolve({ thumbnailURL, fileThumbnail });
      });
    });
  };


  const fileChangeTrailer = async (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files;
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.addEventListener('loadedmetadata', async () => {
          const durationInSeconds = videoElement.duration;
          const formattedDuration = formatDuration(durationInSeconds);
          const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
          const formData = new FormData();
          formData.append('mediaFile', file);
          fileUpload3(fileThumbnail, file, formattedDuration)
         
        });

        videoElement.src = URL.createObjectURL(file);
      }
    }
  };
  const fileUpload3 = async (thumb, file, formattedDuration) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setThumbnail(state.thumbnailLocation)
          setTrailerData1(prevData => [
            ...prevData,
            {
              video: state.videoLocation,
              thumbnail: state.thumbnailLocation,
              duration: formattedDuration,
              type: 1,
              is_active: 1,
            }
          ]
          );
        }
      }
    }
    catch (error) {
    }
  };
  const handleClick = (item) => {
    setVideo(item?.video)
    setShow(!show)
  }

  const handleValidation1 = () => {
    let errors = {};
    let formIsValid = true;
    if (
      selectedOption == 1
    ) {
      if (!thumbnail || thumbnail.trim().length === 0) {
        formIsValid = false;
        errors["thumbnail"] = ERR_MESSAGE.TRAILOR_EMPTY
      }
    }
    else{
      if (!thumbnail || thumbnail.trim().length === 0) {
        formIsValid = false;
        errors["thumbnail"] = ERR_MESSAGE.CLIP_EMPTY
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  async function handleEditAPI(e) {
    e.preventDefault()
    if (handleValidation1()) {
      let data = {
        "seriesId": id,
        "trailerAndClipData": selectedOption == 1 ? trailerData1 : clipData1,
      }
      setLoading(true)
      let result = await apiHelper.postService("creator/add-episode-and-clip", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false)
        NotificationManager.success(result.message)
        setAddTrailorModal(false);
        props.getDetailsAPI()
        setThumbnail('')
      }
      else {
        setLoading(false)
        NotificationManager.error(result.message)
      }
    }

  }
  const handleTrailor = () => {
    navigate(ROUTES.CREATER_TRAILOR_CLIP, { state: { series_clip: props?.seriesDetail?.series_clips } })
  }

  return (
    <div>
      <section className="trailer py-5">
        {isLoading && <ReactLoader />}
        {progressLoading && <>
          <ProgressBarComponent progress={progress} />
        </>}
        <div className="container">
          <div className="chanel-header ">
            <h3 className="fw-600 mb-0">Trailer & Clips</h3>
            <div className=' text-end '>
              {/* <div className='col-6 text-end'> */}
              <button className=" common-btn mx-5" onClick={() => {
                setAddTrailorModal(true)
                setId(props?.seriesDetail?.id)
              }}>Add</button>
              {/* </div> */}
              {/* <button className="see-all fw-600 f18-size" onClick={() => handleTrailor()}>
                See all
                <img src={seeAllArrow} alt="" className="ms-2" />
              </button> */}
              {/* <img src={leftArrow} className="me-3" />
            <img src={seeAllArrow} className="ms-2" /> */}
            </div>
          </div>
          <div className="row mt-4 pt-3 ">
            {
              props?.seriesDetail?.series_clips?.map((item) => {
                return (
                  <div className='col-12 col-sm-6 col-lg-4'>
                    <div key={item?.id} onClick={() => handleClick(item)} className="item mb-5">
                      <div className="trailor-slider-cus ">
                        <img
                          src={item?.thumbnail}
                          className="movie-more br30 movie-clips"
                          alt=""
                        />
                        <div className="trailor-duration">
                          <p className="white mb-1">{props?.modifyDuration(item?.duration)}</p>
                        </div>
                        <div className="trailor-play">
                          <button className="eps-play">
                            <img src={playLightbtn} alt="" className='img-fluid' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      {
        show && <SeriesVideoPlayer show={show} setShow={setShow} video_url={video} />
      }
      {
        addTrailorModal && <AddTrailor setAddTrailorModal={setAddTrailorModal} handleOptionChange={handleOptionChange} selectedOption={selectedOption} setSelectedOption={setSelectedOption} fileChangeTrailer={fileChangeTrailer} thumbnail={thumbnail} handleEditAPI={handleEditAPI} fileChangeClip={fileChangeClip} errors={errors} setErrors={setErrors}/>
      }
    </div>
  )
}

export default Trailors;