import React, { useState, useEffect } from 'react';
import { modalCross, playLightBtn } from '../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from '../../ControlComponents/react-loader';
import VideoPlayModal from '../../CreaterDashboard/Shop/videoPlay';
import { useLocation } from "react-router-dom"
const AddDetailVideoModal = ({ setAddDetailVideoModal, deltId }) => {
    const [detailList, setDetailList] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [videoPlay, setVideoPlay] = useState(false);
    const [url, setUrl] = useState("");
    const location = useLocation()
    const getDetailAPI = async () => {
        setIsLoading(true);
        try {
            const result = await apiHelper.getRequest(`shop/get-shop-media?shopType=video&shop_id=${deltId}`);
            if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
                setDetailList(result.data.filter(item => item.id == deltId));
            } else if (result.code === 401) {
                const refreshToken = localStorage.getItem("refresh-token");
                const data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                };
                const refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
                if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token);
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                    setRefresh(!refresh);
                }
            }
        } catch (error) {
            console.error("Failed to fetch detail list:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getDetailAPI();
    }, [deltId, refresh]);

    return (
        <>
            {loading && <ReactLoader />}
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Detail</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setAddDetailVideoModal(false)
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3 verify-modal-box px-2 px-sm-5">
                            {detailList && (
                                <form className='price-form-input'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h4>{detailList[0]?.title}</h4>
                                            <h6 className="images-count">
                                                {detailList[0]?.shop_media?.length} {detailList[0]?.shop_media?.length === 1 ? "Video" : "Videos"}
                                            </h6>
                                        </div>
                                        {
                                            (localStorage.getItem("role") == 1 && location.pathname != "/user-gifts/gift-content") &&
                                            <div className='detail-main'>
                                                <span className='first-price-detail'>${detailList[0]?.price}</span>
                                            </div>
                                        }

                                    </div>
                                    <p className="image-detail-description">{detailList[0]?.description}</p>
                                    <h4>Videos</h4>
                                    <div className='d-flex align-items-start gap-3 flex-wrap overflow-scroll justify-content-center justify-content-sm-start' style={{ maxHeight: 350, cursor: "pointer" }}>
                                        {detailList[0]?.shop_media?.map((item, index) => (
                                            <div key={index} className='position-relative' onClick={() => {
                                                setVideoPlay(true);
                                                setUrl(item.media_url);
                                            }} style={{ cursor: "pointer" }}>
                                                <div className='multi-images'>
                                                    <img src={item.media_thumbnail} alt="Thumbnail" />
                                                </div>
                                                <img className='detail-video-play' src={playLightBtn} alt="Play Button" />
                                            </div>
                                        ))}
                                    </div>
                                </form>
                            )}
                        </div>
                        {videoPlay && <VideoPlayModal setVideoPlay={setVideoPlay} url={url} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddDetailVideoModal;
