import React from 'react';
import { Lefticons, pinkLeftIcons, pinkRightIcons, Righticons } from '../../../../Constants/images';

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="custom-prev-arrow" onClick={onClick} style={{ cursor: 'pointer' }}>
            <img src={pinkLeftIcons} alt="Previous" />
        </button>
    );
};

const PrevDisbaleArrow = () => {
    return (
        <button className="custom-prev-arrow" style={{ cursor: "not-allowed" }}>
            <img src={Lefticons} alt="Previous" />
        </button>
    );
};

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="custom-next-arrow" onClick={onClick} style={{ cursor: 'pointer' }}>
            <img src={pinkRightIcons} alt="Next" />
        </button>
    );
};

const NextDisableArrow = () => {
    return (
        <button className="custom-next-arrow" style={{ cursor: 'not-allowed' }}>
            <img src={Righticons} alt="Next" />
        </button>
    ); 
};

export { PrevArrow, NextArrow, NextDisableArrow, PrevDisbaleArrow };
