import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SCHEDULE_DOT, live_calender, live_clock, no_live } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'
import { Dropdown } from 'react-bootstrap';
import DeleteContent from './deleteContent';
function BucketLives({ setDeltId, getDeleteAPI, dataList, setDeleteModal, deleteModal }) {
    const navigate = useNavigate()
    const handleDeleteOpen = (id) => {
        setDeltId(id)
        setDeleteModal(true)
    }
    
    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }


    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    const hanldeClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item?.recording_details))
        navigate(ROUTES.CREATER_RECORDED_VIDEO)
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className="fw-600 mb-0" style={{marginLeft:12}}>Recorded Lives</h3>
                <div className='col-6 text-end'>
                    <button className=" common-btn" onClick={() => {
                        navigate(ROUTES.CREATER_BUCKET_LIVES)
                    }}>+Add New</button>
                </div>
            </div>
            <section className="schedule-wrapper ">
                <div className="container-fluid">
                    <div className='row mt-3'>
                        {dataList?.length > 0 ? (
                            <>
                                {dataList?.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" style={{cursor:'pointer'}} >
                                        <div className="latest-video mx-auto" >
                                            <div className='position-relative'
                                            onClick={()=>hanldeClick(item)}>
                                                <div
                                                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={item?.recording_details.thumbnail}
                                                        className="img-fluid latest-vid-img"
                                                        alt="thumbnail"
                                                    />

                                                </div>
                                            </div>
                                            <div className="latest-video-cus px-3 py-3 schedule-grid">
                                                <div className=''>
                                                    <h4 className="schedule-title">{item?.recording_details.name}</h4>
                                                    <div className="timing-flex">
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_calender} alt="calendar" />
                                                            <span className='ps-1'>{dateFunction(item.recording_details.start_time)}</span>
                                                        </div>
                                                        <div className='mb-0 timing-inner-flex'>
                                                            <img src={live_clock} alt="clock" />
                                                            <span className='ps-1'>{addTime(item.recording_details.start_time)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dotschedule bg-transparent'>
                                                    <div className='dropdownschedule'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="custom-dropdown-toggle">
                                                                <img src={SCHEDULE_DOT} className='img-fluid' alt="dot" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu onClick={() => handleDeleteOpen(item.id)}>
                                                                <Dropdown.Item >Remove</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : <>
                            
                               
                                
                                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={no_live} alt="No Challenges" />
                                    </div>
                                
                            
                        </>
                        }
                    </div>
                </div>
            </section>
            {deleteModal && <DeleteContent setDeleteModal={setDeleteModal} check='lives' getDeleteAPI={getDeleteAPI} />}

        </div>
    )
}

export default BucketLives