import React, { useEffect, useState } from 'react'
import apiHelper from '../../../../Helper/api-helper';
import ReactLoader from '../../../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import './categories.css';
import 'swiper/css';
import { leftArrow, seeAllArrow } from '../../../../Constants/images';


function Categories(props) {

   const [catList, setCatList] = useState([])
   const [refresh, setRefresh] = useState(false)

   async function getAPI() {
      props?.setIsLoading(true)
      let result = await apiHelper.getRequest("auth/category-list")
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setCatList(result.data)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }

      }
   }

   useEffect(() => {
      getAPI()
   }, [refresh]);

   const handleOnClick = (id) => {
      props?.setCategory(id)
   }

   return (
      <div>

         <section className="streaming py-6 mt-5 response-padding">
            <div className="container">
               <div className="row">
               <div className='d-flex align-items-center justify-content-between mb-4'>
               {
                  catList?.length > 0 
                  && <><h3 className="fw-600 mb-0">Streaming Categories</h3>
                  {/* <div className='text-end '>
                  <img src={leftArrow} className="me-3" />
                     <img src={seeAllArrow} className="ms-2" />
                   
                  </div> */}
                  </>
               }
                     
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                     {
                        catList?.length > 0 &&  <button onClick={() => handleOnClick(0)} className={props.category == 0 ? "common-btn btn-blue btn-shape" : "btn-white"}>All</button>
                     }
                     <div className="stream-btn white-height">
                        {catList?.map((category) =>
                           <button onClick={() => handleOnClick(category.id)} key={category.id}
                              className={props.category == category.id ? "common-btn btn-blue" : "btn-white "}>{category.name}</button>
                        )}
                     </div>

                     {/* </Swiper> */}


                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}

export default Categories;