import React, { useEffect, useRef, useState } from 'react'
import {useNavigate } from 'react-router-dom'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ReactLoader from '../../ControlComponents/react-loader'
import './seriesDetail.css'
import Video from './video'
import Episodes from './episodes'
import Trailors from './trailors'
import ROUTES from '../../Helper/route-helper'
import BitesSeries from './bitesSeries'
import { NotificationManager } from 'react-notifications'
import DeleteSeriesModal from './deleteSeriesModal'
import SeriesChat from './seriesChat'
import CastCreator from './castCreator'

function SeriesDetail() {
  const ref = useRef()
  const [activeComponent, setActiveComponent] = useState(0);
  const [seriesDetail, setSeriesDetail] = useState(null)
  const navigate = useNavigate()
  const [id, setId] = useState(localStorage.getItem("series_id"))
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [creatorName, setCreatorName] = useState(null)
  const [creatorImg, setCreatorImg] = useState(null)
  const [chatOpen, setChatOpen] = useState(false)
  const handleOpen = () => {
    setDeleteModal(!deleteModal)
  }

  async function getMyDetailsAPI() {
    let result = await apiHelper.getRequest("auth/get-my-detail")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCreatorName(result?.data?.name);
      setCreatorImg(result.data?.profile_pic)
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult?.data?.token)
        localStorage.setItem("refresh-token", refreshTokenResult?.data?.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/my-series-details?id=" + id)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setSeriesDetail(result?.data)
      const trimmedName = result?.data?.title?.replace(/\s/g, '');;
      const chatId = `${result?.data?.id}${trimmedName}`;
      localStorage.setItem("chatId", chatId)
      setChatOpen(true)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  async function getDeleteAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/delete-series?seriesId=" + id)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      setDeleteModal(false)
      localStorage.setItem("message", result.message)
      navigate(ROUTES.CREATER_SERIES);
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
    else {
      NotificationManager.error(result.message)
    }
  }

  useEffect(() => {
    getDetailsAPI()
    getMyDetailsAPI()
  }, [refresh, id])

  const handleEdit = () => {
    navigate(ROUTES.CREATER_EDIT_SERIES, { state: { id } })
  }

  function modifyDuration(duration) {
    const durationParts = duration?.split(':');
    if (durationParts && durationParts[0] === '00') {
      return durationParts.slice(1)?.join(':');
    }
    return duration;
  }

  return (
    <div ref={ref} className="main-inner-content">
      {isLoading && <ReactLoader />}
      <Video
        creatorName={creatorName} creatorImg={creatorImg} seriesDetail={seriesDetail} handleEdit={handleEdit} handleOpen={handleOpen} setIsLoading={setIsLoading} id={id}/>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15, cursor: "pointer" }} className='container'>
        <div className="stream-btn white-height">
          <button className={activeComponent == 0 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(0)}>Episodes</button>
          <button className={activeComponent == 1 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(1)}>Cast & Crew</button>
          <button className={activeComponent == 2 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(2)}>Bites</button>
          <button className={activeComponent == 3 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(3)}>Trailor & Clips</button>
          <button className={activeComponent == 4 ? "common-btn btn-blue" : "common-btn btn-white"} onClick={() => setActiveComponent(4)}>Comments</button>
        </div>
      </div>
      
      {activeComponent === 0 && <Episodes seriesDetail={seriesDetail} modifyDuration={modifyDuration} getDetailsAPI={getDetailsAPI} chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />}
      {activeComponent === 1 && <CastCreator seriesDetail={seriesDetail} />}
      {activeComponent === 2 && <BitesSeries seriesDetail={seriesDetail} creatorName={creatorName} creatorImg={creatorImg} />
      }
      {activeComponent === 3 && <Trailors seriesDetail={seriesDetail} modifyDuration={modifyDuration} getDetailsAPI={getDetailsAPI} />
      }
      {activeComponent === 4 && <SeriesChat chatOpen={chatOpen} creatorName={creatorName} creatorImg={creatorImg} />
      }

       
      <section className='bottom-button'>
        {deleteModal && <DeleteSeriesModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />}
      </section>
      <section>
      </section>
    </div>
  )
}

export default SeriesDetail;