import React from 'react';
import { no_subscribers, search,profile_image_placeholder, dotsbg } from '../../Constants/images';
import './subscribers.css'
import { Dropdown } from 'react-bootstrap';

function Subscribers({ subscriberList ,handleChange}) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className="fw-600 mb-0 mt-4 mb-4">Subscribers</h3>
        <div className=' text-end main-search'>
          <div className='sub-search position-relative '>
            <input type='text' className='input-search' placeholder='search' 
             onChange={(e) => handleChange(e.target.value)}
             />
            <img src={search} alt="" className="search-img icon-search-tier" />
          </div>
        </div>
      </div>
     
      <section className="favchannel-main-cus">
        <div className="container-fluid">
          <div className="row">
            {
              subscriberList.length > 0 ? <>
                {
                  subscriberList?.map((item) => (
                    <div className="bucket-sub-wrapper mb-4 position-relative">
                     
                      <div className="bucketsubscribe position-relative channels-box mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                        <img src={item.user_details.profile_pic ? item.user_details.profile_pic : profile_image_placeholder} 
                        className="img-fluid bucket-img" />
                        
                        <h4 className="channel-text fw-600 my-2">{item?.user_details?.name}</h4>
                        <p className="channel-para mb-0 text-pink">Expiry date : {formatDate(item.expiry_date)}</p>
                      </div>
                      
                    </div>
                  ))
                }
              </>
                :
                <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                  <img src={no_subscribers} alt="No Challenges" />
                </div>
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default Subscribers;